import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import NoAccess from '../access/NoAccess';
import { isBetaUser, isLoggedIn } from '../helpers/AuthHelpers';
import { isBoolean } from '../helpers/ObjectHelpers';
import { RouteGuardStates } from '../../services/SystemNames';
import Login from '../access/Login';
import ValidateAccess from '../access/ValidateAccess';

const RouteGuardLoggedIn = ({ beta: Beta }) => {

    const [guardState, setGuardState] = useState(RouteGuardStates.Busy);

    useEffect(() => {

        let canContinue = true;

        if (isBoolean(Beta)) {
            if (Beta) {
                canContinue = isBetaUser();
            }
        }

        if (canContinue) {
            setGuardState(isLoggedIn() ? RouteGuardStates.Valid : RouteGuardStates.Login);
        } else {
            setGuardState(RouteGuardStates.Login);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        (() => {
            switch (guardState) {
                case RouteGuardStates.Valid:
                    return (<Outlet />);
                case RouteGuardStates.NoAccess:
                    return (<NoAccess />);
                case RouteGuardStates.Login:
                    return (<Login />);

                default:
                    return (<ValidateAccess />);
            }
        })()
    )
};

export default RouteGuardLoggedIn
