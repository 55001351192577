import './NavigationBurgerMenu.css';

import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconColors, NavItemTypes } from '../../../services/SystemNames';
import packageJson from '../../../../package.json';
import { useEffect } from 'react';
import PubSub from 'pubsub-js';
import { slide as BurgerMenu } from '@katasonovyp/react-burger-menu'
import Icons from '../icons/Icons';
import MetaIdbRepository from '../../../repositories/idb/MetaIdbRepository';
import PilotApiRepository from '../../../repositories/api/PilotApiRepository';
import PilotAssignmentIdbRepository from '../../../repositories/idb/PilotAssignmentIdbRepository';
import NavigationBurgerMenuContent from './components/NavigationBurgerMenuContent';
import Moment from 'moment';
import { NavigationMobileSearchDialog } from './components/NavigationMobileSearchDialog';
import { Dialog } from '../dialogs/Dialog';
import Overlay from '../overlay/Overlay';
import EvaluationIdbRepository from '../../../repositories/idb/EvaluationIdbRepository';
import { DialogProperties } from '../dialogs/DialogProperties';
import { Button } from "@progress/kendo-react-buttons";
import { useLocation } from 'react-router-dom';
import { useGetApplicationRightsQuery, useGetHseTypeGroupsQuery, useGetSourceConfigQuery, useLazyGetHseTypeGroupsQuery } from '../../../reducers/slices/api.slice';
import { isArrayEmpty, isNullOrEmpty, isObjectNull } from '../../helpers/ObjectHelpers';
import { publishRefreshedDataTopic, PubSubTopics } from '../../helpers/PubSubHelpers';
import { getElementsByClassName } from '../../helpers/ElementHelpers';
import { convertPilotAssignmentApiDtoToIdb } from '../../../pages/pilot/pilot-assignment/helpers/PilotAssignmentHelpers';
import { getTokenExpireDate } from '../../helpers/TokenHelpers';
import { handleLogin, initializeNavAsync } from '../../helpers/NavHelpers';
import { initCacheAsync } from '../../helpers/CacheHelpers';
import { DarkModeSwitch } from 'react-toggle-dark-mode';
import { isDarkTheme, toggleTheme } from '../../helpers/ThemeHelpers';

// eslint-disable-next-line no-empty-pattern
export const NavigationBurgerMenu = forwardRef(({
    newVersionRef,
    isNewVersionAvailable,
    setIsNewVersionAvailable
}, ref) => {

    useImperativeHandle(ref, () => ({
        toggle() {
            setIsOpen(!isOpen);
        }
    }));

    const {
            data: hseTypeGroups,
            isSuccess: isHseTypeGroupsSuccess
        } = useGetHseTypeGroupsQuery();

    const {
        data: applicationRights,
        isSuccess: isApplicationRightsSuccess,
    } = useGetApplicationRightsQuery();

    const {
        data: sourceConfig,
        isSuccess: isSourceConfigSuccess
    } = useGetSourceConfigQuery();

    const navigate = useNavigate();
    const location = useLocation();

    const defaultTitle = "Njord Pilot Web";

    const [navItems, setNavItems] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isBusy, setIsBusy] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [isLoggingOut, setIsLoggingOut] = useState(false);
    const [environment, setEnvironment] = useState("");
    const [showRefreshDialog, setShowRefreshDialog] = useState(false);
    const [toggleItems, setToggleItems] = useState(Moment().valueOf());
    const [showSearchDialog, setShowSearchDialog] = useState(false);
    const [isDarkMode, setIsDarkMode] = useState(isDarkTheme());

    useEffect(() => {

        PubSub.subscribe(PubSubTopics.BurgerMenu, handleMessage);

        setIsOpen(false);

        window.addEventListener("offline", handleOffline);
        window.addEventListener("online", handleOnline);

        initializeAsync();

        return (() => {
            PubSub.unsubscribe(PubSubTopics.BurgerMenu);

            window.removeEventListener("offline", handleOffline);
            window.removeEventListener("online", handleOnline);
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isNullOrEmpty(isHseTypeGroupsSuccess) || isNullOrEmpty(isApplicationRightsSuccess)) return;
        initializeAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isHseTypeGroupsSuccess, isApplicationRightsSuccess]);

    useEffect(() => {
        toggleNewVersionMenuItem();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isNewVersionAvailable]);

    useEffect(() => {
        setIsOpen(false);
    }, [location]);

    useEffect(() => {
        if (isNullOrEmpty(isSourceConfigSuccess) || !isSourceConfigSuccess) return;
        setEnvironment(sourceConfig.environment);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSourceConfigSuccess]);

    return (
        <>
            {/*
            Warning: Menu: Support for defaultProps will be removed from function components in a future major release.
            Use JavaScript default aprameters insted.
            Update BurgerMenu, or find replacement!
            */}
            <BurgerMenu right isOpen={isOpen} onClose={onClose} onOpen={() => setIsOpen(true)}>
                <div className="bm-menu-buttons">
                    <div className="p-l-20">
                        <DarkModeSwitch
                            checked={isDarkMode}
                            onChange={() => {
                                toggleTheme();
                                setIsDarkMode(!isDarkMode);
                            }} />
                        {/*<Button*/}
                        {/*    onClick={() => {*/}
                        {/*        setIsOpen(false);*/}
                        {/*        setShowSearchDialog(true);*/}
                        {/*    }}>*/}
                        {/*    <NavigationBurgerMenuIcon icon="search"/>*/}
                        {/*</Button>*/}
                    </div>
                    <div />
                    <div>
                        <Button
                            fillMode="flat"
                            onClick={handleOnOverlayClick}>
                            <NavigationBurgerMenuIcon icon="close" />
                        </Button>
                    </div>
                </div>
                    
                <div className="bm-menu-content">
                    <div>
                        <div id="sm-parent" className={`sm-parent`}>
                            <div className="sm-tm">
                                <NavigationBurgerMenuContent
                                    toggleItems={toggleItems}
                                    setToggleItems={() => setToggleItems(Moment().valueOf())}
                                    newVersionAvailable={isNewVersionAvailable}
                                    navItems={navItems}
                                    onNavItemClick={onNavItemClickAsync}
                                />
                            </div>
                            <div></div>
                        </div>
                    </div>
                </div>
            </BurgerMenu>

            {
                (showDialog && !isLoggingOut) &&
                <Dialog
                    properties={{
                        ...DialogProperties,
                        title: defaultTitle,
                        onClose: () => setShowDialog(false),
                        actions: [
                            {
                                onClick: () => setShowDialog(false),
                                icon: "ok",
                                themeColor: "primary",
                                text: "Ok"
                            }
                        ]
                    }}>
                    <div>Versjon {packageJson.version}</div>
                    {
                        !isNullOrEmpty(environment) && <div>{environment}</div>
                    }
                    <div>Exp: {getTokenExpireDate()}</div>
                </Dialog>
            }

            {
                (showDialog && isLoggingOut) &&
                <Dialog
                    properties={{
                        ...DialogProperties,
                        mode: "confirmation",
                        title: "Logg ut",
                        text: "&Oslash;nsker du &aring; logge ut av NjordPilotWeb?",
                        onClose: () => setShowDialog(false),
                        onClick: onLogoutClick
                    }} />
            }

            {
                showRefreshDialog &&
                <Dialog
                    properties={{
                        ...DialogProperties,
                        mode: "confirmation",
                        title: "Resette data",
                        text: "&Oslash;nsker du &aring; resette data? B&aring;de losoppdrag og evalueringer etter farledspr&oslash;ve vil bli tilbakestilt til data lagret p&aring; server.",
                        onClose: () => setShowRefreshDialog(false),
                        onClick: refreshAsync
                    }} />
            }


            {
                showSearchDialog &&
                <NavigationMobileSearchDialog
                    setShowSearchDialog={setShowSearchDialog}
                />
            }

            <Overlay isBusy={isBusy} />
        </>
    );

    function handleMessage(msg, data) {

        switch (data.action) {
            case PubSubTopics.LoggedIn:
            case PubSubTopics.Logout:
                initializeAsync();
                break;

            default:
                if (!isOpen) return;
                setIsOpen(false);
                break;
        }
    }

    function toggleNewVersionMenuItem() {

        const elems = getElementsByClassName("sm-lnk-itm-new-version");
        if (isArrayEmpty(elems)) return;

        const elem = elems[0];
        if (isObjectNull(elem)) return;

        if (isNewVersionAvailable) {
            elem.style.display = "grid";
        } else {
            elem.style.display = "none";
        }
    }

    async function initializeAsync() {
        var items = await initializeNavAsync(hseTypeGroups, applicationRights);
        setNavItems(items);
    }

    function handleOffline() {
        initializeAsync();
    }

    function handleOnline() {
        initializeAsync();
    }

    function handleOnOverlayClick() {
        onClose();
    }

    function onLogoutClick() {
        setShowDialog(false);
        navigate("/logout");
    }

    function onAbout() {
        setIsLoggingOut(false);
        setShowDialog(true);

        onClose();
    }

    function onLogin() {
        handleLogin();
    }

    function onLogout() {
        setIsLoggingOut(true);
        setShowDialog(true);

        onClose();
    }

    async function onNavItemClickAsync(navItem) {
        const hasChildren = navItem.children !== undefined && navItem.children.length > 0;

        if (hasChildren === false) {
            setIsOpen(false);

            switch (navItem.type) {
                case NavItemTypes.Login:
                    onLogin();
                    break;

                case NavItemTypes.Logout:
                    onLogout();
                    break;

                case NavItemTypes.About:
                    onAbout();
                    break;

                case NavItemTypes.RefreshData:
                    setShowRefreshDialog(true);
                    break;

                case NavItemTypes.NewVersion:
                    newVersionRef.current.reload();
                    setIsNewVersionAvailable(false);
                    break;

                default:
                    if (isNullOrEmpty(navItem.to) === false) {
                        navigate(navItem.to);
                    }
                    break;
            }
            return;
        }
    }

    function onClose() {
        setIsOpen(false);
        setToggleItems(Moment().valueOf());
    }

    async function refreshAsync() {
        setShowRefreshDialog(false);
        setIsBusy(true);

        await MetaIdbRepository.deleteCacheLastExpireDateAsync();
        await initCacheAsync(sourceConfig);
        await EvaluationIdbRepository.deleteAllAsync();
        await PilotAssignmentIdbRepository.deleteAllAsync();
        await PilotApiRepository.deleteAllPushSubscriptionsAsync();

        const response = await PilotApiRepository.getAssignedPilotagesAsync();
        if (response.ok === true) {
            const data = await response.json();
            data.forEach(async (dto) => {
                const pilotAssignment = convertPilotAssignmentApiDtoToIdb(dto);
                await PilotAssignmentIdbRepository.setAsync(pilotAssignment);
            });
        }

        setIsBusy(false);

        publishRefreshedDataTopic();
    }
});

const NavigationBurgerMenuIcon = ({ icon }) => {
    return (
        <Icons iconName={icon} color={IconColors.Primary} dimensions={24} />
    )
}
