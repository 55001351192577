import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import CompensationApiRepository from '../../repositories/api/CompensationApiRepository';
import ContentGrid from '../../components/layout/ContentGrid';
import FloatingButtonContainer from '../../components/layout/FloatingButtonContainer';
import FloatingActionButton from '../../components/layout/FloatingActionButton';
import { PlusIcon } from '../../components/layout/icons/Icons';
import './styles/compensation.css';
import CompensationCard from './CompensationCard';
import { PilotOvertimeDialog } from './components/dialogs/PilotOvertimeDialog';
import { PilotHourCompensationDialog } from './components/dialogs/PilotHourCompensationDialog';
import { publishHeaderTopic } from '../../components/helpers/PubSubHelpers';
import { isNullOrEmpty, isObjectNull } from '../../components/helpers/ObjectHelpers';
import Overlay from '../../components/layout/overlay/Overlay';
import Switch from '../../components/layout/switch';

const CompensationOverview = () => {

    const [searchParams] = useSearchParams();
    const correctionParam = searchParams.get('correction');

    const [compensations, setCompensations] = useState(null);
    const [correctionChecked, setCorrectionChecked] = useState(getCorrectionParam());
    const [showOvertimeDialog, setShowOvertimeDialog] = useState(false);
    const [showHourCompensationDialog, setShowHourCompensationDialog] = useState(false);
    const [isBusy, setIsBusy] = useState(false);

    useEffect(() => {
        initializeAsync();
    }, []);

    async function initializeAsync() {
        setIsBusy(true);
        await CompensationApiRepository.getPilotCompensations()
            .then((response) => response.json())
            .then((result) => {
                setCompensations(result);
                publishHeaderTopic("Tillegg");
                setIsBusy(false);
            })
            .catch((error) => { console.error(error); })
    }

    function CorrectionToggle () {
        return (
            <div>
                <Switch
                    checked={correctionChecked}
                    onChange={() => setCorrectionChecked(!correctionChecked)}
                    onLabel="Kun korrigering"
                />
            </div>
        )
    }

    function Corrections() {
        if (!correctionChecked) return;
        const corrections = isObjectNull(compensations) ? [] : compensations.filter((compensation) => compensation.isCorrectionRequired);
        if (isNullOrEmpty(corrections)
            || !compensations.some((comp) => comp.isCorrectionRequired))
            return (
                <div className="compensation-no-entries">
                    Du har ingen tillegg som krever korrigering
                </div>
            )
        return (
            <>
                {corrections.map((compensation, index) =>
                    <div key={index}>
                        <CompensationCard compensation={compensation} reload={initializeAsync} />
                    </div>
                )}
            </>
        )
    }

    function onAddItemClick(e) {
        switch (e.itemProps.action) {
            case "overtime":
                setShowOvertimeDialog(true);
                break;
            case "hourCompensation":
                setShowHourCompensationDialog(true);
                break;
            default:
                return;
        }
    }

    function getCorrectionParam() {
        if (isNullOrEmpty(correctionParam)) return false;

        try {
            return Boolean(correctionParam);
        } catch {
            return false;
        }
    }

    return (
        <>
            <ContentGrid title={"Tillegg"}
                titleIcon="compensationHand"
                canPullToRefresh={true}
                onPullToRefresh={initializeAsync}>
            <div className="compensation-content">
                {isNullOrEmpty(compensations) && !isBusy ?

                    <div className="pp-cards-empty">
                        Du har ingen registrerte tillegg
                    </div>
                    :
                    <>
                        <CorrectionToggle />
                        <Corrections />
                        {(compensations && !correctionChecked) &&
                            compensations.map((compensation, index) =>
                                <div key={index}>
                                    <CompensationCard
                                        compensation={compensation}
                                        reload={initializeAsync}
                                    />
                                </div>
                        )}

                    </>
                }
                {showOvertimeDialog &&
                    <PilotOvertimeDialog
                        onClose={() => setShowOvertimeDialog(false)}
                        canEdit={true}
                        reload={() => initializeAsync()}
                    />
                }
                {showHourCompensationDialog &&
                    <PilotHourCompensationDialog
                        onClose={() => setShowHourCompensationDialog(false)}
                        canEdit={true}
                        reload={() => initializeAsync()}
                    />
                }
            </div>
            <FloatingButtonContainer hasBottomNavigation={false}>
                <FloatingActionButton
                    themeColor="primary"
                    svgIcon={PlusIcon}
                    onItemClick={onAddItemClick}
                    items={[
                        {
                            text: "Overtid",
                            svgIcon: PlusIcon,
                            action: "overtime",
                        },
                        {
                            text: "C-tillegg",
                            svgIcon: PlusIcon,
                            action: "hourCompensation",
                        }
                    ]}
                />
            </FloatingButtonContainer>
            </ContentGrid>

            <Overlay isBusy={isBusy} />
        </>
    );
}

export default CompensationOverview;
