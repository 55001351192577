/* eslint-disable react-hooks/exhaustive-deps */

import './header.css'

import React, { useEffect, useState } from 'react';
import PubSub from 'pubsub-js';
import { useNavigate } from 'react-router-dom';
import Icons from '../icons/Icons';
import {
    AppBar,
} from "@progress/kendo-react-layout";
import { HeaderAlerts } from './HeaderAlerts';
import parse from 'html-react-parser';
import CopyToClipboard from '../CopyToClipboard';
import { isNullOrEmpty } from '../../helpers/ObjectHelpers';
import { PubSubTopics } from '../../helpers/PubSubHelpers';
import { isLoggedIn } from '../../helpers/AuthHelpers';

export default function Header({ burgerButtonClick }) {

    
    const navigate = useNavigate();
    const [headerData, setHeaderData] = useState({
        title: "",
        subTitle: "",
        buttons: [],
        canGoBack: true
    });
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

    const headerInterval = React.useRef(null);

    useEffect(() => {
        PubSub.subscribe(PubSubTopics.UpdateHeader, handleHeaderTopic);

        initializeAlertsAsync();

        headerInterval.current = setInterval(() => initializeAlertsAsync(), 30000);// Check for alerts every 30 seconds

        return () => {
            PubSub.unsubscribe(PubSubTopics.UpdateHeader);

            if (!headerInterval.current) {
                clearInterval(headerInterval.current);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        isUserLoggedIn &&
        <>
            <AppBar className="app-bar">
                <div>
                    {
                        (headerData.canGoBack) &&
                        <button
                            className="k-button k-button-md k-rounded-md k-button-flat k-button-flat-base"
                            onClick={handleOnBackClick}>
                            <Icons iconName="back" />
                        </button>                    
                    }
                </div>
                <div onClick={() => navigate("/")} className="pointer">
                    <div>
                        Njord<b>Pilot</b>Web
                    </div>
                </div>
                <div>
                    <div className="text-truncate">
                        {
                            (!isNullOrEmpty(headerData.title)) &&
                                headerData.canCopyTitle ?
                                <CopyToClipboard text={headerData.title} />
                                : parse(headerData.title)
                        }
                    </div>
                    <div> {!isNullOrEmpty(headerData.subTitle) && " : " }</div>
                    <div className="text-truncate">
                        {
                            (!isNullOrEmpty(headerData.subTitle)) &&
                                headerData.canCopySubTitle ?
                                <CopyToClipboard text={headerData.subTitle} />
                                : parse(headerData.subTitle)
                        }
                    </div>
                </div>
                <div>
                    <HeaderAlerts/>
                {/*    <div className="app-bar-search">*/}
                {/*        {*/}
                {/*            isLoggedIn && */}
                {/*                <HeaderAppBarSearch/>*/}
                {/*        }*/}
                {/*    </div>*/}
                </div>
                <div>
                    <button
                        className="k-button k-button-md k-rounded-md k-button-flat k-button-flat-base"
                        onClick={handleBurgerButtonClick}>
                        <Icons iconName="hamburger" />
                    </button>
                </div>
            </AppBar>
        </>
    );

    function handleHeaderTopic(msg, data) {
        switch (data.action) {
            case PubSubTopics.UpdateHeader:
                setHeaderData(data);
                break;
            default:
                initializeAlertsAsync();
                break;
        }
    }
    
    async function initializeAlertsAsync() {
        setIsUserLoggedIn(isLoggedIn());
    }
    
    function handleOnBackClick() {
        PubSub.publish(PubSubTopics.SelectBottomMenuItem, '');
        navigate(-1);
    }

    function handleBurgerButtonClick() {
        burgerButtonClick();
    }
}

