import "./quaymanual.css"

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import QuayManualApiRepository from '../../repositories/api/QuayManualApiRepository';
import PanelBar from "../../components/layout/PanelBar";
import QuayObservation from "./QuayObservation";
import ContentGrid from '../../components/layout/ContentGrid';
import QuayDocumentCard from "./components/QuayDocumentCard";
import Overlay from "../../components/layout/overlay/Overlay";
import Slide from "../../components/layout/Slide";
import QuayPDF from "./components/QuayPDF";
import Spacer from "../../components/layout/Spacer";
import { publishHeaderTopic, publishWarningNotificationTopic } from "../../components/helpers/PubSubHelpers";
import { isNullOrEmpty } from "../../components/helpers/ObjectHelpers";

const QuayLocation = ({ searchedLocation }) => {

    const [locationData, setLocationData] = useState([]);
    const {locationid} = useParams();
    const [showNoDataMessage, setShowNoDataMessage] = useState(false);
    const [isBusy, setIsBusy] = useState(true);
    const [hasErrors, setHasErrors] = useState(false);
    const [showPdf, setShowPdf] = useState(false);

    const initializeAsync = async () => {
        let locationId;
        if (!isNullOrEmpty(searchedLocation)) {
            locationId = searchedLocation.locationId;
        } else {
            locationId = locationid;
        }
        var response = await QuayManualApiRepository.getByLocationAndProximity(locationId);
        setHasErrors(!response.ok);
        if(response.ok)
        {
            var result = await response.json();
            if(result === null || result.length === 0)
            {
                setShowNoDataMessage(true);
            }
            setLocationData(result);
        }
        else
        {
            publishWarningNotificationTopic("Det oppstod en feil ved kommunikasjon med tjenesten");
        }
        setIsBusy(false);
    };

    useEffect(() => {
        publishHeaderTopic("Kaibok");
        initializeAsync();
    }, []);

    return (
        <>
            <ContentGrid title="Kaibok" titleIcon="book">
                <div className="quaymanual">
                    {showNoDataMessage ?
                        <div className="message">Det finnes ingen kaibøker for denne lokasjonen.</div>
                        :
                        locationData.map((item) => (
                                <PanelBar key={item.locationId}  title={item.name} className="quaymanual-panelbar">
                                    <div className="quaymanual-blinds-content">
                                        {item.documents.map((doc) => (
                                            <PanelBar key={doc.binaryContentId} title={doc.name} className="quaymanual-panelbar">
                                                <QuayDocumentCard
                                                    onClick={() => setShowPdf(!showPdf)}
                                                    doc={doc} />
                                                <Slide show={showPdf}>
                                                    <QuayPDF documentId={showPdf ? doc.binaryContentId : 0} />
                                                </Slide>
                                                <Spacer height={3} />
                                                <QuayObservation doc={doc} />
                                            </PanelBar>
                                        ))}
                                    </div>
                                </PanelBar>
                        ))
                    }
                </div>
            </ContentGrid>

            <Overlay
                isBusy={isBusy}
                hasErrors={hasErrors}
                onReloadClick={() => {
                    setHasErrors(false);
                    setIsBusy(true);
                    initializeAsync();
                }}
            />
        </>
    );
};

export default QuayLocation;
