import Moment from 'moment';
import React, { Fragment } from 'react';
import { HistoryTypes, IconColors } from '../../../services/SystemNames';
import Icons from '../../../components/layout/icons/Icons';
import { BusySpinner } from '../../../components/layout/overlay/components/Busy';
import ContentGrid from '../../../components/layout/ContentGrid';
import { useNavigate } from 'react-router-dom';

export const PilotHistoryContent = ({ pilotHistory, isLoading }) => {
    return (
        <ContentGrid title="Min historikk" titleIcon="timeReset">
            <div className="history-items">
                {pilotHistory.map((historyData, index) => <PilotHistoryItem key={index} historyData={historyData} />)}
                {isLoading &&
                    <BusySpinner dimensions={40} />
                }
            </div>
        </ContentGrid>
    );
}

export const PilotHistoryItem = ({ historyData }) => {
    const navigate = useNavigate();
    var direction = historyData.description.substring(0, historyData.description.indexOf(' '));
    var location = historyData.description.substring(direction.length);

    const handlePilotageClick = (historyData) => {
        return () => { navigate(`/pilotage/${historyData.pilotageId}`); };
    };

    return (
        <Fragment>
            <div className="hst-header">
                <Icons color={IconColors.Primary} dimensions={20} iconName={getIcon(historyData.type)}/>
                <div className="hst-header-direction">{direction}</div>
                <div>{`${location}`}</div>
            </div>
            {historyData.shipName != null && <div className='link-btn p-b-10' onClick={handlePilotageClick(historyData)}>{historyData.shipName} • {historyData.pilotageNo}</div>}
            <div className="history-timestamp">{Moment(historyData.timeStamp).format('DD MMMM • HH:mm')} </div>
            <div className="horizontal-line" />
        </Fragment>
    );
}

const getIcon = (type) => {
    switch (type) {
        case HistoryTypes.Appointment:
            return "calendar";
        case HistoryTypes.Piloting:
            return "ship";
        case HistoryTypes.WorkTime:
            return "clock";
        case HistoryTypes.RestTime:
            return "bed";
        case HistoryTypes.AvailableTime:
            return "information";
        case HistoryTypes.OvertimeTravelTime:
            return "information";
        default:
            return "dummy";
    }
}
