import React from 'react';
import ContentGrid from '../../../components/layout/ContentGrid';
import './pilotage-details.css';
import Spacer from '../../../components/layout/Spacer';
import PilotagePecExamsInfo from './PilotagePecExamsInfo';
import PilotageDetailLocation from './PilotageDetailLocation';
import PilotageDetailsAssignment from './PilotageDetailsAssignment';
import PilotageDetailsTransportOrders from './PilotageDetailsTransportOrders';
import { Card } from '../../../components/layout/card/Card';
import { CardProperties } from '../../../components/layout/card/components/CardProperties';
import { MiniCard } from '../../../components/layout/card/MiniCard';
import { isNullOrEmpty } from '../../../components/helpers/ObjectHelpers';
import { getPilotAssignmentStatusColor, getPilotAssignmentStatusColorRgba } from '../pilot-assignment/helpers/PilotAssignmentHelpers';

export default function PilotageDetailsDefault({ onReload, state: pilotage, isReadOnly }) {

    return (
        <>
            <ContentGrid
                title={pilotage.pilotageShip.shipName}
                subTitle={`${pilotage.pilotageNo}`}
                canCopySubTitle={true}
                titleIcon="ship">
                <div className="details-content">
                    <PilotageDetailLocation
                        state={pilotage}
                        isReadOnly={isReadOnly}
                    />
                    {
                        !(isNullOrEmpty(pilotage.pilotageDetail.remark) &&
                            isNullOrEmpty(pilotage.pilotageDetail.dispatcherRemark)) &&
                            <Card>
                                <Spacer height={10} />
                                {
                                    !isNullOrEmpty(pilotage.pilotageDetail.remark) &&
                                    <>
                                        <div className="details-card-title">Oppdragsmerknad</div>
                                        <Spacer height={10} />
                                        <div>{pilotage.pilotageDetail.remark}</div>
                                    </>
                                }
                                {
                                    !isNullOrEmpty(pilotage.pilotageDetail.dispatcherRemark) &&
                                    <>
                                        <Spacer height={15} />
                                        <div className="details-card-title">Melding fra losformidler</div>
                                        <Spacer height={10} />
                                        <div>{pilotage.pilotageDetail.dispatcherRemark}</div>
                                    </>
                                }
                        </Card>
                    }


                    <PilotageDetailsTransportOrders
                        pilotageId={pilotage.pilotageId}
                        handleUpdate={onReload}
                    />

                    {(!isNullOrEmpty(pilotage.pilotagePilotsInformation) ||
                        !isNullOrEmpty(pilotage.assistancePhone)) &&
                        <Card>
                            <Spacer height={10} />
                            {!isNullOrEmpty(pilotage.pilotagePilotsInformation) &&
                                <>
                                    <div className="details-card-title">
                                        {pilotage?.pilotagePilotsInformation && "Loser"}
                                    </div>
                                    <Spacer height={20} />
                                </>
                            }
                                {!isNullOrEmpty(pilotage.pilotagePilotsInformation) &&
                                pilotage?.pilotagePilotsInformation?.map((pilot, index) =>
                                    <div key={index} className="pilot-container">
                                        <MiniCard
                                            item={{
                                                borderColor: getPilotAssignmentStatusColor(pilot),
                                                text: `${pilot.lastName} ${pilot.initials}`,
                                                isExaminer: pilot.isExaminer,
                                                isTrainee: pilot.isTrainee
                                            }}
                                        />
                                        <a href={`tel: ${pilot.phoneNumber}`} className="pilot-phone-link">
                                            {`${pilot.phoneNumber}`}
                                        </a>
                                        <Spacer />
                                    </div>
                                )
                            }
                            {
                                !isNullOrEmpty(pilotage.assistancePhone) &&
                                <>
                                    <Spacer height={15} />
                                    <div>
                                        <div className="details-card-title">
                                            Vakttelefon agent
                                        </div>
                                        <Spacer height={5} />
                                        <a href={`tel: ${pilotage.assistancePhone}`} className="phone-link">
                                            {`${pilotage.assistancePhone}`}
                                        </a>
                                    </div>
                                </>
                            }
                        </Card>
                    }
                    {
                    !isNullOrEmpty(pilotage.pecExams) &&
                        <Card properties={{
                                ...CardProperties,
                                title: "Farledspr&oslash;ve"
                        }} className="details-card">
                        {
                            pilotage.pecExams.map((pec, index) =>
                                <div key={index}>
                                    <PilotagePecExamsInfo
                                        pecExam={pec}
                                        pilotageId={pilotage.pilotageId}
                                    />
                                </div>
                            )
                        }
                    </Card>
                    }
                    {
                    !isReadOnly &&
                    <PilotageDetailsAssignment
                        pilotageId={pilotage.pilotageId}
                        updatePilotagePilot={onReload}
                    />
                    }
                </div>
                <Spacer height={30} />
            </ContentGrid>
        </>
    )
}
