import './styles/notice-time.css';

import React, { useEffect, useState } from 'react';
import Moment from 'moment';
import { Card } from '../../components/layout/card/Card';
import Icons, { UploadIcon } from '../../components/layout/icons/Icons';
import NoticeTimeRow from './components/NoticeTimeRow';
import Spacer from '../../components/layout/Spacer';
import Overlay from '../../components/layout/overlay/Overlay';
import FloatingActionButton from '../../components/layout/FloatingActionButton';
import FloatingButtonContainer from '../../components/layout/FloatingButtonContainer';
import NoticeTimeApiRepository from '../../repositories/api/NoticeTimeApiRepository';
import ContentGrid from '../../components/layout/ContentGrid';
import { NoticeTimeBottomNavigation } from './components/NoticeTimeBottomNavigation';
import { publishHeaderTopic, publishSuccessNotificationTopic, publishWarningNotificationTopic } from '../../components/helpers/PubSubHelpers';
import { getTimeMinutes } from '../../components/helpers/DataHelpers';

function General() {

    const [isBusy, setIsBusy] = useState(true);
    const [hasErrors, setHasErrors] = useState(false);
    const [canSave, setCanSave] = useState(false);
    const [showWarning, setShowWarning] = useState(false);
    const [noticeTime, setNoticeTime] = useState();

    const [minutes, setMinutes] = useState([]);
    const [selectedMinuteDay, setSelectedMinuteDay] = useState(null);
    const [selectedMinuteNight, setSelectedMinuteNight] = useState(null);
    const [dayTime, setDayTime] = useState("");
    const [nightTime, setNightTime] = useState("");

    useEffect(() => {
        publishHeaderTopic("Generell varslingstid");
        initializeAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <ContentGrid
                title="Varslingstid"
                titleIcon="alarmClock"
                subTitle={"Generell varslingstid"}>
                <Card>
                    <Spacer height={30} />
                    <div className="container">
                        <div className="row align-items-center">
                            <NoticeTimeRow
                                icon={<Icons iconName="day" />}
                                time={dayTime}
                                selectedMinute={selectedMinuteDay}
                                minutes={minutes}
                                onChange={handleOnDayChange}
                            />
                        </div>
                        <div className="row align-items-center">
                            <Spacer height={20} />
                        </div>
                        <div className="row align-items-center">
                            <NoticeTimeRow
                                icon={<Icons iconName="night" />}
                                time={nightTime}
                                selectedMinute={selectedMinuteNight}
                                isDay={false}
                                minutes={minutes}
                                onChange={handleOnNightChange}
                            />
                        </div>
                    </div>

                    {
                        showWarning &&
                        <>
                            <Spacer />
                            <div className="container font-warning">
                                <div className="row">
                                    <div className="col">
                                        Endring av varslingstid vil medf&oslash;re at lokasjoner som har lik varslingstid, og ikke er tilknyttet en gruppe, vil bli satt tilbake til generell varslingstid.
                                    </div>
                                </div>
                            </div>
                        </>
                    }

                    <Spacer height={30} />
                </Card>
                <Spacer height={70} />
            </ContentGrid>

            <FloatingButtonContainer hasBottomNavigation={true}>
                <FloatingActionButton
                    themeColor={canSave ? "primary" : "secondary"}
                    disabled={!canSave}
                    svgIcon={UploadIcon}
                    onClick={onSaveAsync}
                />
            </FloatingButtonContainer>

            <NoticeTimeBottomNavigation/>

            <Overlay isBusy={isBusy} onReloadClick={onReloadClick} hasErrors={hasErrors} />
        </>
    );

    async function initializeAsync() {
        const response = await NoticeTimeApiRepository.getGeneralAsync();

        if (response.ok) {
            const data = await response.json();

            data.originalDefaultMinutesBeforeToNoticeDay = data.defaultMinutesBeforeToNoticeDay;
            data.originalDefaultMinutesBeforeToNoticeNight = data.defaultMinutesBeforeToNoticeNight;

            setNoticeTime(data);

            const timeMinutes = getTimeMinutes(false);
            setMinutes(timeMinutes);

            const selectedMinuteDay = timeMinutes.find(m => Number(m.value) === Number(data.defaultMinutesBeforeToNoticeDay));
            setSelectedMinuteDay(selectedMinuteDay);

            const selectedMinuteNight = timeMinutes.find(m => Number(m.value) === Number(data.defaultMinutesBeforeToNoticeNight));
            setSelectedMinuteNight(selectedMinuteNight);

            const dayTimeStart = Moment("2000-01-01").add(data.dayStartTimeInMinutes, 'minutes');
            setDayTime(dayTimeStart.format("HH:mm"));

            const nightTimeStart = Moment("2000-01-01").add(data.dayStartTimeInMinutes, 'minutes');
            setNightTime(nightTimeStart.format("HH:mm"));
            
            setCanSave(false);

        } else {
            handleError(response.status);
        }

        setIsBusy(false);
    }

    async function onSaveAsync() {
        
        setIsBusy(true);

        const response = await NoticeTimeApiRepository.setGeneralAsync(noticeTime);

        if (response.ok === true) {
            publishSuccessNotificationTopic(`Generell varslingstid ble lagret.`);
            initializeAsync();
        } else {
            handleError(response.status);
            setIsBusy(false);
        }
    }


    function onReloadClick() {
        setIsBusy(true);
        setHasErrors(false);
        initializeAsync();
    }

    function handleOnDayChange(e) {
        const minute = e.target.value;
        setSelectedMinuteDay(minute);

        noticeTime.defaultMinutesBeforeToNoticeDay = minute.value;
        handleChange(noticeTime);
    }

    function handleOnNightChange(e) {
        const minute = e.target.value;
        setSelectedMinuteNight(minute);

        noticeTime.defaultMinutesBeforeToNoticeNight = minute.value;
        handleChange(noticeTime);
    }

    function handleChange(e) {
        e.defaultChanged = true;
        updateNoticeTime(e);
        validate(e);
    }

    function validate(noticeTime) {
        const hasDefaultChanged = noticeTime.hasOwnProperty("defaultChanged") ? noticeTime.defaultChanged : false;
        setCanSave(hasDefaultChanged);

        setShowWarning(noticeTime.originalDefaultMinutesBeforeToNoticeDay !== noticeTime.defaultMinutesBeforeToNoticeDay ||
            noticeTime.originalDefaultMinutesBeforeToNoticeNight !== noticeTime.defaultMinutesBeforeToNoticeNight);
    }

    function updateNoticeTime(newNoticeTime) {
        setNoticeTime(noticeTime => ({
            ...noticeTime,
            ...newNoticeTime
        }));
    }

    function handleError(response) {
        publishWarningNotificationTopic("En feil oppstod ved behandling av generell varslingstid.", response.status);
        setIsBusy(false);
        setHasErrors(true);
    }
}

export default General;
