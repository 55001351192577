import { ApplicationInsights } from '@microsoft/applicationinsights-web'

export const AppInsightsService = {

  // https://github.com/microsoft/applicationinsights-js?tab=readme-ov-file#configuration
async initAsync(connectionString, getLoggedInPersonId) {
    if (connectionString) {
      this.ai = new ApplicationInsights({ config: {
        connectionString: connectionString,
        enableUnhandledPromiseRejectionTracking: true,
      } });

      var loggedInPersonId = getLoggedInPersonId();
      
      this.ai.loadAppInsights();
      this.ai.setAuthenticatedUserContext(`${loggedInPersonId}`);
    } else {
      throw new Error("An error occurred while initializing Application Insights.");
    }
  },
  // https://github.com/microsoft/applicationinsights-js?tab=readme-ov-file#sending-telemetry-to-the-azure-portal
  async trackTrace(message) {
    console.debug(`Logging trace to application insights: ${message}`);
    if(this.ai) 
      this.ai.trackTrace({ message: message});
  },

  async trackException(error) {
    console.debug(`Logging exception to application insights: ${error.message}`);
    if(this.ai) 
      this.ai.trackException({ exception: error});
  }
}

export default AppInsightsService;
