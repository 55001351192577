import "./styles/pilotage.css";
import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from 'react-router-dom';
import { PageStates } from "../../services/SystemNames";
import { useNavigate } from "react-router-dom";
import PilotApiRepository from "../../repositories/api/PilotApiRepository";
import { PageStateCurrent } from "./components/PilotagesPageStates";
import AllPilotagesBottomMenu from "./components/AllPilotagesBottomMenu";
import FavouriteStations from "../../components/layout/favourite_stations/FavouriteStations";
import PilotStationApiRepository from "../../repositories/api/PilotStationApiRepository";
import MetaIdbRepository from "../../repositories/idb/MetaIdbRepository";
import Overlay from "../../components/layout/overlay/Overlay";
import ContentGrid from '../../components/layout/ContentGrid';
import { publishHeaderTopic, publishInfoNotificationTopic, publishWarningNotificationTopic } from "../../components/helpers/PubSubHelpers";
import { isNumeric } from "../../components/helpers/ObjectHelpers";
import { scrollCardElementIntoView } from "../../components/helpers/ElementHelpers";

function All() {

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const [pageState, setPageState] = useState(PageStates.Default);
    const [httpStatusCode, setHttpStatusCode] = useState(0);
    const [pilotages, setPilotages] = useState([]);
    const [stations, setStations] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const loggedInPersonId = useRef(0);

    useEffect(() => {
        getLoggedInPersonId();
        publishHeaderTopic("Alle oppdrag");
        return () => {};
    }, []);

    useEffect(() => {
        getStations();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setIsLoading(true);
        fetchPilotStationPilotagesAsync();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stations]);

    useEffect(() => {
        const pilotageIdParam = searchParams.get("pilotageId");
        if (isNumeric(pilotageIdParam)) {
            scrollCardElementIntoView(pilotageIdParam);
        }
    }, [pilotages, searchParams]);

    return (
        <>
            {(() => {
                switch (pageState) {
                    case PageStates.Default:
                        return (
                            <PageStateCurrent
                                pilotages={pilotages}
                                onClick={handlePilotagePilotClick}
                                onRefresh={() => {
                                    setIsLoading(true);
                                    fetchPilotStationPilotagesAsync();
                                }}
                            />
                        );
                    case PageStates.PilotStationSelector:
                        return (
                            <ContentGrid
                                title="Stasjoner"
                                titleIcon="star"
                            >
                                <FavouriteStations />
                            </ContentGrid>
                        );
                    case PageStates.Error:
                        return (
                            <Overlay
                                hasErrors={true}
                                onReloadClick={onReloadClick}
                                statusCode={httpStatusCode}
                            />
                        );
                    default:
                        return null;
                }
            })()}

            <Overlay isBusy={isLoading} />

            <AllPilotagesBottomMenu
                pageState={pageState}
                onClick={handleTabChanged}
            />
        </>
    );

    async function getLoggedInPersonId() {
        const whoAmI = await MetaIdbRepository.getWhoAmIAsync();
        loggedInPersonId.current = whoAmI.personId;
    }

    async function fetchPilotStationPilotagesAsync() {
        await PilotApiRepository.searchCurrentPilotagesAsync(
            stations.map((ps) => ps.departmentID),
        )
            .then((response) => {
                return response.json();
            })
            .then((result) => {
                setPilotages(result);
            })
            .catch((error) => {
                handleError(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    async function getStations() {
        await PilotStationApiRepository.getFavouriteStations()
            .then((response) => {
                return response.json();
            })
            .then((result) => {
                if (result.length > 0) {
                    setStations(result);
                } else {
                    publishInfoNotificationTopic("Du har ingen valgte favoritter.");
                }
            })
            .catch((error) => {
                handleError(error);
            });
    }

    async function handleTabChanged(newState) {
        if (newState === PageStates.Default) {
            await getStations();
        }
        setPageState(newState);
    }

    function handlePilotagePilotClick(pilotage) {
        let url = `/pilot/pilotage/${pilotage.pilotageId}`;

        if (!pilotage.pilotagePilotsInformation.some(
            (p) => p.pilotPersonId === loggedInPersonId.current)) {
            url = `/pilotage/${pilotage.pilotageId}`;
        }

        navigate(`/pilotages/current?pilotageId=${pilotage.pilotageId}`, { replace: true });
        navigate(url);
    }

    function handleError(response) {
        publishWarningNotificationTopic("Det oppstod en feil ved kommunikasjon med tjeneste");
        setHttpStatusCode(response.status);
        setPageState(PageStates.Error);
    }

    function onReloadClick() {
        setPageState(PageStates.Default);
    }
}

export default All;
