import Home from "./pages/home/Index";
import Logout from "./pages/home/Logout";
//import HomeDemo from "./pages/home/Demo";

import HseEdit from "./pages/hse/Edit";

import EvalIndex from "./pages/evaluation/Index";
import PecExamEvaluation from './pages/evaluation/PecExamEvaluation';
import PecExamEvaluationSummary from './pages/evaluation/PecExamEvaluationSummary';

import PcsGroups from "./pages/pcs/Groups";
import PcsLocations from "./pages/pcs/Locations";
import PcsEvaluations from "./pages/pcs/Evaluations";

import SettingsCalendar from "./pages/settings/Calendar";
import SettingsPersonalData from "./pages/settings/PersonalData";
import SettingsDayNight from "./pages/settings/DayNight";
import LocationSharing from "./pages/settings/notifications/LocationSharing";
import PilotNotifications from "./pages/settings/notifications/PushNotifications";

import NoticeTimeGeneral from "./pages/noticetime/General";
import NoticeTimeLocations from "./pages/noticetime/Locations";
import NoticeTimeOverridden from "./pages/noticetime/Overridden";

import PilotRotationCurrent from "./pages/pilot-rotation/Current";
import PilotRotationNext from "./pages/pilot-rotation/Next";
import PilotRotationLog from "./pages/pilot-rotation/Log";

import WorkTimeRegister from "./pages/worktime/Register";
import WorkTimeSimulate from "./pages/worktime/Simulate";
import WorkTimeTimeline from "./pages/worktime/Timeline";
import WorkTimeFuture from "./pages/worktime/Future";

import PilotWorkActual from "./pages/pilot/work/Actual";
import PilotPilotagesAssigned from "./pages/pilot/pilotages/Assigned";
import PilotagePilotagesCurrent from "./pages/pilotages/Current";
import PilotageDetailsReadOnly from "./pages/pilotages/Details"
import PilotPilotageDetails from "./pages/pilot/pilotages/Details";
import PilotAppointment from "./pages/pilot/appointments/Appointment";

import PilotHistory from "./pages/pilot/History";
import { ApplicationRightSystemNames, PersonTypes } from "./services/SystemNames";

import QuayStations from "./pages/quaymanual/QuayStations";
import QuayDispatcherDepartments from "./pages/quaymanual/QuayDispatcherDepartments";
import QuayLocations from "./pages/quaymanual/QuayLocations";
import QuayLocation from "./pages/quaymanual/QuayLocation";

import PilotStations from "./pages/pilot-station/PilotStations";

import CompensationOverview from "./pages/compensation/CompensationOverview";
import { PilotageCompensationOverview } from "./pages/compensation/PilotageCompensationOverview";


export const AppRoutesDefault = [
    {
        element: <Logout />,
        path: '/logout'
    },
    //{
    //    path: '/demo',
    //    element: <HomeDemo />,
    //    beta: true
    //},
];


export const AppRoutesLoggedInPerson = [
    // Evaluation
    {
        path: '/evaluation',
        element: <EvalIndex />
    },
    {
        path: '/evaluation/pecExamEvaluation',
        element: <PecExamEvaluation />
    },
    {
        path: '/evaluation/pecExamEvaluation-summary',
        element: <PecExamEvaluationSummary />
    },
]

export const AppRoutesLoggedIn = [
    {
        element: <Home />,
        path: '/'
    },
    // Pilotage
    {
        path: '/pilotages/current',
        element: <PilotagePilotagesCurrent />
    },
    {
        path: '/pilotage/:id',
        element: <PilotageDetailsReadOnly />
    },

]

export const AppRoutesPersonType = [
    // HSE
    {
        path: '/hse/:id',
        element: <HseEdit />,
        type: PersonTypes.Pilot,
        onlineOnly: true
    },

    // Settings
    {
        path: '/settings/calendar',
        element: <SettingsCalendar />,
        type: PersonTypes.Pilot,
        onlineOnly: true
    },
    {
        path: '/settings/personaldata',
        element: <SettingsPersonalData />,
        type: PersonTypes.Pilot,
        onlineOnly: true
    },
    {
        path: '/settings/daynight',
        element: <SettingsDayNight />,
        type: PersonTypes.Pilot,
        onlineOnly: true
    },
    {
        path: '/settings/notifications/locations',
        element: <LocationSharing />,
        type: PersonTypes.Pilot,
        onlineOnly: true
    },
    {
        path: '/settings/notifications',
        element: <PilotNotifications />,
        type: PersonTypes.Pilot,
        onlineOnly: true
    },

    // NoticeTime
    {
        path: '/noticetime/general',
        element: <NoticeTimeGeneral/>,
        type: PersonTypes.Pilot,
        onlineOnly: true
    },
    {
        path: '/noticetime/locations',
        element: <NoticeTimeLocations />,
        type: PersonTypes.Pilot,
        onlineOnly: true
    },
    {
        path: '/noticetime/overridden',
        element: <NoticeTimeOverridden />,
        type: PersonTypes.Pilot,
        onlineOnly: true
    },
    // Worktime
    {
        path: '/worktime/register',
        element: <WorkTimeRegister />,
        type: PersonTypes.Pilot,
        onlineOnly: true
    },
    {
        path: '/worktime/simulate',
        element: <WorkTimeSimulate />,
        type: 'PILOT',
        onlineOnly: true
    },
    {
        path: '/worktime/timeline',
        element: <WorkTimeTimeline />,
        type: 'PILOT',
        onlineOnly: true
    },
    {
        path: '/worktime/future',
        element: <WorkTimeFuture />,
        type: 'PILOT',
        onlineOnly: true
    },

    // Pilot
    {
        path: '/pilot/work/actual',
        element: <PilotWorkActual />,
        type: PersonTypes.Pilot,
        onlineOnly: true
    },
    {
        path: '/pilot/pilotages/assigned',
        element: <PilotPilotagesAssigned />,
        type: PersonTypes.Pilot,
        onlineOnly: false
    },
    {
        path: '/pilot/pilotage/:id',
        element: <PilotPilotageDetails />,
        type: PersonTypes.Pilot,
        onlineOnly: false
    },
    {
        path: '/pilot/appointments',
        element: <PilotAppointment />,
        type: 'PILOT',
        onlineOnly: true
    },
    // Compensations
    {
        path: '/pilot/compensation',
        element: <CompensationOverview />,
        type: 'PILOT',
        onlineOnly: true,
    },
    {
        path: '/pilot/compensation/pilotage/:id',
        element: <PilotageCompensationOverview />,
        type: 'PILOT',
        onlineOnly: true,
    },
    // History
    {
        path: '/pilot/history',
        element: <PilotHistory />,
        type: 'PILOT',
        onlineOnly: true
    },

    // Quay
    {
        path: '/pilot/quaymanual',
        element: <QuayDispatcherDepartments />,
        type: 'PILOT',
        onlineOnly: true
    },
    {
        path: '/pilot/quaymanual/location/:locationid',
        element: <QuayLocation />,
        type: 'PILOT',
        onlineOnly: true
    },
    {
        path: '/pilot/quaymanual/:department',
        element: <QuayStations />,
        type: 'PILOT',
        onlineOnly: true
    },
    {
        path: '/pilot/quaymanual/:department/:station',
        element: <QuayLocations />,
        type: 'PILOT',
        onlineOnly: true
    },
    {
        path: '/pilot/station',
        element: <PilotStations />,
        type: 'PILOT',
        onlineOnly: true
    },
];

export const AppRoutesApplicationRights = [
    // PilotRotation
    {
        path: '/pilot-rotation/current',
        element: <PilotRotationCurrent />,
        applicationRights: [ApplicationRightSystemNames.PilotRotation],
        onlineOnly: true
    },
    {
        path: '/pilot-rotation/next',
        element: <PilotRotationNext />,
        applicationRights: [ApplicationRightSystemNames.PilotRotation],
        onlineOnly: true
    },
    {
        path: '/pilot-rotation/log',
        element: <PilotRotationLog />,
        applicationRights: [ApplicationRightSystemNames.PilotRotation],
        onlineOnly: true
    },

    // PCS
    {
        path: '/pcs/groups',
        element: <PcsGroups />,
        applicationRights: [ApplicationRightSystemNames.EditPilotCoastalSegments],
        onlineOnly: true
    },
    {
        path: '/pcs/locations',
        element: <PcsLocations />,
        applicationRights: [ApplicationRightSystemNames.EditPilotCoastalSegmentsLocations],
        onlineOnly: true
    },
    {
        path: '/pcs/evaluations',
        element: <PcsEvaluations />,
        applicationRights: [ApplicationRightSystemNames.EditPilotCoastalSegmentsEvaluation],
        onlineOnly: true
    },
];


