import './googlemap.css'

import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import {
    APIProvider,
    Map
} from '@vis.gl/react-google-maps';
import { GoogleMapProperties } from './GoogleMapProperties';
import { MapControls } from './components/MapControls';
import { MapProviderSeaMap } from './components/MapProviderSeaMap';
import { MapSelectedPositionMarker } from './components/MapSelectedPositionMarker';
import { MapSelectedPositionCircle } from './components/MapSelectedPositionCircle';
import { MapPilotageMarkers } from './components/MapPilotageMarkers';
import { MapLegend } from './components/MapLegend';
import { MapClickPosition } from './components/MapClickPosition';
import { MapEvaluation } from './components/MapEvaluation';
import { MapLocationMarkers } from './components/MapLocationMarkers';
import { MapPilotCoastalSegmentGroups } from './components/MapPilotCoastalSegmentGroups';
import { MapListeners } from './components/MapListeners';
import { MapPilotCoastalSegmentGroup } from './components/MapPilotCoastalSegmentGroup';
import SourceApiRepository from '../../../repositories/api/SourceApiRepository';
import { MapEvents } from './components/MapEvents';
import { MapPecExamFairwayAreas } from './components/MapPecExamFairwayAreas';
import { MapAis } from './components/MapAis';
import uuid from 'react-uuid';
import PilotApiRepository from '../../../repositories/api/PilotApiRepository';
import { deepCopyObject, isArrayEmpty, isNullOrEmpty, isNumeric } from '../../helpers/ObjectHelpers';
import { getNumericUrlParam } from '../../helpers/UrlHelpers';
import { getTileUrl } from '../../helpers/GeometryHelpers';
import { isDarkTheme } from '../../helpers/ThemeHelpers';
import { MapSettings } from './components/MapSettings';
import { setMapTitle } from './GoogleMapHelpers';
import { useGetSourceConfigQuery } from '../../../reducers/slices/api.slice';

export const GoogleMapCard = forwardRef((
    {
        properties = { ...GoogleMapProperties }

    }, ref) => {

    useImperativeHandle(ref, () => ({
        onFeatureMouseOver(e) {
            setFeatureMouseOver(e);
        },
        onFeatureMouseOut() {
            setFeatureMouseOver(null);
        },
        onFeatureSelected(e) {
            setFeatureSelected(e);
        },
        onSetSelectedPosition(e) {
            setSelectedPosition(e);
        },
        onToggleSelected() {
            onSelected();
        },
        onUpdatePcsTitle(title) {
            setMapTitle(mapId, title);
        },
        onCancelMultiSelect() {
            pilotCoastalSegmentGroupRef.current.onCancelMultiSelect();
        },
        onResetPilotCoastalSegmentGroups() {
            pilotCoastalSegmentGroupsRef.current.onReset();
        }
    }));

    const [isBusy, setIsBusy] = useState(true);
    const [config, setConfig] = useState(null);
    const [selectedPosition, setSelectedPosition] = useState(null);
    const [isSeamapActive, setIsSeamapActive] = useState(false);
    const [isLocationsActive, setIsLocationsActive] = useState(null);
    const [toggleMarkerInfoWindows, setToggleMarkerInfoWindows] = useState(null);
    const [featureMouseOver, setFeatureMouseOver] = useState(null);
    const [featureSelected, setFeatureSelected] = useState(null);
    const [pilotage, setPilotage] = useState(null);
    const [shipPosition, setShipPosition] = useState(null);
    const [mapId, setMapId] = useState("");

    const componentRef = useRef({
        circle: null,
        seamap: null,
        isSeamapActive: false,
        isLocationsActive: false
    });
    const { current: localRef } = componentRef;

    const settingsRef = useRef();
    const controlsRef = useRef();
    const pilotageMarkersRef = useRef();
    const locationMarkersRef = useRef();
    const pilotCoastalSegmentGroupsRef = useRef();
    const pilotCoastalSegmentGroupRef = useRef();
    const aisRef = useRef();

    const {
        data: sourceConfig,
        isSuccess: isSourceConfigSuccess
    } = useGetSourceConfigQuery();

    useEffect(() => {
        if (isNullOrEmpty(isSourceConfigSuccess)) return;

        setMapId(uuid());

        const lat = getNumericUrlParam("lat");
        const lng = getNumericUrlParam("lng");
        const config = deepCopyObject(sourceConfig);

        config.defaultMapLocation = JSON.parse(config.defaultMapLocation);

        if (isNumeric(lat) &&
            isNumeric(lng) &&
            lat > 0 && lng > 0) {
            config.defaultMapLocation = { lat: lat, lng: lng };
        }

        setConfig(config);
        
        setIsBusy(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSourceConfigSuccess]);

    useEffect(() => {
        if (!isNumeric(properties.pilotageId)) return;
        if (Number(properties.pilotageId) === 0) return;

        async function initializeAsync() {
            const pilotageId = Number(properties.pilotageId);
            const response = await PilotApiRepository.getReadOnlyPilotageByIdAsync(pilotageId);
            if (response.ok) {
                const data = await response.json();
                setPilotage(data);
            } else {
                setPilotage(null);
            }
        }

        initializeAsync();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [properties]);

    useEffect(() => {

        if (!isNumeric(properties.shipId)) return;
        if (Number(properties.shipId) === 0) return;

        searchShipPositionAsync();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [properties]);

    return (
        !isBusy &&
            <div className="googlemap-container" id={mapId}>
                <APIProvider onLoad={onApiProviderLoad} apiKey={config.googleMapApiKey} language="no">
                    <Map onClick={onMapClick}
                        defaultCenter={config.defaultMapLocation}
                        defaultZoom={properties.zoom}
                        minZoom={properties.minZoom}
                        mapId={isDarkTheme() ? config.googleDarkMapId : config.googleMapId}
                        mapTypeControl={properties.mapTypeControl}
                        zoomControl={properties.zoomControl}
                        fullscreenControl={properties.fullscreenControl}
                        streetViewControl={properties.streetViewControl}
                        rotateControl={properties.rotateControl}>

                        <MapSettings
                            ref={settingsRef}
                            mapId={mapId}
                            properties={properties}
                            pilotage={pilotage}
                            shipPosition={shipPosition}
                            onSeaMapClick={toggleSeamap}
                            onLocationsClick={toggleLocations}
                        />

                        <MapListeners
                            properties={properties}
                            onBoundsChanged={onBoundsChanged}
                            onZoomChanged={onZoomChanged}
                            onDragEnd={onDragEnd}
                            onResize={onResize}
                            setToggleMarkerInfoWindows={setToggleMarkerInfoWindows}
                        />

                        <MapEvents
                            mapId={mapId}
                            featureMouseOver={featureMouseOver}
                            featureSelected={featureSelected}
                        />

                        <MapControls
                            ref={controlsRef}
                            mapId={mapId}
                            properties={properties}
                            onMultiSelectClick={onMultiSelectClick}
                            onMultiSelectOkClick={onMultiSelectOkClick}
                            isLocationsActive={isLocationsActive}
                        />
                        <MapProviderSeaMap
                            isSeamapActive={isSeamapActive}
                            localRef={localRef} />

                        <MapSelectedPositionMarker
                            properties={properties}
                            position={selectedPosition} />

                        <MapSelectedPositionCircle
                            properties={properties}
                            localRef={localRef}
                            position={selectedPosition} />

                        <MapClickPosition
                            properties={properties}
                            selectedPosition={selectedPosition}
                            setSelectedPosition={setSelectedPosition}
                        />

                        <MapPilotageMarkers
                            ref={pilotageMarkersRef}
                            pilotage={pilotage}
                            shipPosition={shipPosition}
                            toggleMarkerInfoWindows={toggleMarkerInfoWindows}
                            setToggleMarkerInfoWindows={setToggleMarkerInfoWindows}
                        />

                        <MapEvaluation properties={properties} />

                        <MapLocationMarkers
                            ref={locationMarkersRef}
                            properties={properties}
                            isLocationsActive={isLocationsActive}
                            toggleMarkerInfoWindows={toggleMarkerInfoWindows}
                            setToggleMarkerInfoWindows={setToggleMarkerInfoWindows}
                        />

                        <MapPilotCoastalSegmentGroups
                            ref={pilotCoastalSegmentGroupsRef}
                            mapId={mapId}
                            properties={properties}
                        />

                        <MapPilotCoastalSegmentGroup
                            ref={pilotCoastalSegmentGroupRef}
                            mapId={mapId}
                            properties={properties}
                            config={config}
                        />

                        <MapPecExamFairwayAreas
                            mapId={mapId}
                            properties={properties}
                        />

                        <MapLegend
                            properties={properties}
                            pilotage={pilotage}
                            shipPosition={shipPosition}
                            mapId={mapId}
                            onSettingsLegend={onSettingsLegend}
                        />

                        <MapAis
                            ref={aisRef}
                            properties={properties}
                            toggleMarkerInfoWindows={toggleMarkerInfoWindows}
                            setToggleMarkerInfoWindows={setToggleMarkerInfoWindows}
                        />
                    </Map>
                </APIProvider>
            </div>
    );

    async function searchShipPositionAsync() {
        const shipId = Number(properties.shipId);
        const response = await SourceApiRepository.getShipPositionsAsync([shipId]);

        let result = null;

        if (response.ok) {
            const data = await response.json();
            if (!isArrayEmpty(data)) {
                result = data[0];
            }
        }

        setShipPosition(result);
    }

    function onApiProviderLoad() {
        localRef.seamap = new window.google.maps.ImageMapType({
            getTileUrl: getTileUrl,
            name: "Sj�kart",
            alt: "Norge / Sj�kart",
            minZoom: 0,
            maxZoom: 19,
            opacity: 1.0
        });

        setIsLocationsActive(properties.showLocations);
    }

    function onMapClick(e) {

        e.stop();

        setToggleMarkerInfoWindows(uuid());

        if (!properties.canMapClick) return;

        setSelectedPosition(e.detail.latLng);
        properties.handleOnMapClick(e.detail.latLng);
    }

    function toggleSeamap(isActive) {
        localRef.isSeamapActive = isActive;
        setIsSeamapActive(localRef.isSeamapActive);
    }

    function toggleLocations(isActive) {
        localRef.isLocationsActive = isActive;
        setIsLocationsActive(localRef.isLocationsActive);
    }

    function onMultiSelectClick() {
        pilotCoastalSegmentGroupRef.current.onCancelMultiSelect();
    }

    function onMultiSelectOkClick() {
        pilotCoastalSegmentGroupRef.current.onOkMultiSelect();
    }

    function onBoundsChanged() {
        pilotageMarkersRef.current.onBoundsChanged()
        locationMarkersRef.current.onBoundsChanged();
        pilotCoastalSegmentGroupsRef.current.onBoundsChanged();
        pilotCoastalSegmentGroupRef.current.onBoundsChanged();
        aisRef.current.onBoundsChanged();
    }

    function onZoomChanged() {
        controlsRef.current.onZoomChanged();
        pilotageMarkersRef.current.onZoomChanged();
        locationMarkersRef.current.onZoomChanged();
        pilotCoastalSegmentGroupRef.current.onZoomChanged();
        aisRef.current.onZoomChanged();

        onChange();
    }

    function onDragEnd() {
        pilotageMarkersRef.current.onDragEnd();
        locationMarkersRef.current.onDragEnd()
        pilotCoastalSegmentGroupRef.current.onDragEnd();
        aisRef.current.onDragEnd();

        onChange();
    }

    function onResize() {
        pilotageMarkersRef.current.onResize();
        aisRef.current.onResize();
        
        onChange();
    }

    function onSelected() {
        pilotCoastalSegmentGroupsRef.current.onSelected();
    }

    function onSettingsLegend() {
        settingsRef.current.deactivateLegend();
    }

    function onChange() {
        if (!isNumeric(properties.shipId)) return;
        if (Number(properties.shipId) === 0) return;

        searchShipPositionAsync();
    }
    
});
