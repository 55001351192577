import './styles/pilot-rotation.css';

import React, { useState, useRef, useMemo } from 'react';

import {
    PilotRotationTypes
} from '../../services/SystemNames';
import Moment from 'moment';
import PilotRotationApiRepository from '../../repositories/api/PilotRotationApiRepository';
import ContentGrid from '../../components/layout/ContentGrid';
import Overlay from '../../components/layout/overlay/Overlay';
import { BusySpinner } from '../../components/layout/overlay/components/Busy';
import BottomMenu from './components/BottomMenu';
import { useEffect } from 'react';
import { publishHeaderTopic, publishWarningNotificationTopic } from '../../components/helpers/PubSubHelpers';
import { addElementListener, hasScrolledToBottom, removeElementListener } from '../../components/helpers/ElementHelpers';

export default function Log() {

    const [isBusy, setIsBusy] = useState(true);
    const [hasErrors, setHasErrors] = useState(false);
    const [isFetchingMore, setIsFetchingMore] = useState(false);
    const items = useMemo(() => [], []);

    const componentRef = useRef({
        canFetchMore: false
    });
    const { current: local } = componentRef;

    useEffect(() => {
        publishHeaderTopic("T&oslash;rn", "Logg");
        initializeAsync();
        addElementListener("appContent", "scroll", onScroll);

        return () => {
            removeElementListener("appContent", "scroll", onScroll);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Functions for rendering page
    return (
        <>
            <ContentGrid
                title="Logg"
                titleIcon="document"
                canPullToRefresh={true}
                onPullToRefresh={initializeAsync}
            >
                <div className="pilot-rotation-timeline">
                    {items.map((item, index) => (
                        <div className="timeline-item" key={index}>
                            <RenderLogItem items={items} item={item} index={index} />
                        </div>
                    ))}
                    {
                        isFetchingMore &&
                            <BusySpinner type="bars" dimensions={40} />
                    }
                </div>
            </ContentGrid>

            <BottomMenu selectedAction={3} />

            <Overlay isBusy={isBusy} onReloadClick={onReloadClick} hasErrors={hasErrors} />
        </>
    );

    async function initializeAsync() {

        const searchQuery = { fromDate: getToTime().format('YYYY-MM-DDTHH:mm:ss') };
        const response = await PilotRotationApiRepository.searchLogAsync(searchQuery);

        if (response.ok === true) {
            const data = await response.json();
            data.forEach((item) => {
                items.push(item);
            });

            local.canFetchMore = data.length > 0;
            setIsFetchingMore(false);
            setIsBusy(false);

        } else {
            handleError(response.status);
        }
    }

    function getToTime() {
        if (items.length > 0) {
            return Moment(items[items.length - 1].createdDate).add(-1, 'S');
        }
        return Moment();
    }

    function onReloadClick() {
        setIsBusy(true);
        setHasErrors(false);
        initializeAsync();
    }

    function handleError(response) {
        publishWarningNotificationTopic("Kunne ikke hente inn t&oslash;rn logg", response.status);
        setIsBusy(false);
        setHasErrors(true);
    }

   

    function onScroll() {
        if (local.canFetchMore === false) return;

        if (!hasScrolledToBottom()) return;

        setIsFetchingMore(true);

        initializeAsync();
    }
}

const RenderLogItem = ({ items, item, index }) => {
    return (
        <>
            <div className="visualization">
                <div className={'counter ' + getPositiveNegative(item, 'counter')}>
                    {getCount(item)}
                </div>
                <div className="dot-line">
                    <div className="line"></div>
                    <div className={'dot ' + getPositiveNegative(item, 'dot')}></div>
                </div>
            </div>
            <div className="description">
                <div className="date">{item.createdDateDescription}</div>
                <div className="name">{item.personFullName}</div>
                <div className="text">{item.remark}</div>
                {
                    item.hasPilotage ?
                        <React.Fragment>
                            <div className="pilotage">{item.pilotageNo}, {item.shipName}</div>
                            <div className="pilotage">Start: {item.pilotageRedundantStartTimeDescription}</div>
                        </React.Fragment>
                        :
                        <React.Fragment />
                }
                {
                    (item.modifiedByOtherPerson && item.type === PilotRotationTypes.Standard) ?
                        <div className="changed-by">Endret av: {item.modifiedByPersonFullName}</div>
                        :
                        <React.Fragment />
                }
                {
                    ((index + 1) < items.length) ?
                        <div className="spacer"></div>
                        :
                        <React.Fragment />
                }

            </div>

        </>
    );

    function getPositiveNegative(item, css) {
        switch (item.type) {
            case PilotRotationTypes.Change:
                return ` ${css}-neutral`;
            default:
                return ` ${css}-${item.isPositive ? 'positive' : 'negative'}`;
        }
    }

    function getCount(item) {
        if (item.pilotageCount === 0) return item.pilotageCount;

        const pilotageCount = item.pilotageCount >= 0 ? `+${item.pilotageCount}` : `${item.pilotageCount}`;
        return `${pilotageCount} (${item.pilotageCountAfter})`;
    }
}