import './home.css';

import React, { useEffect, useState } from 'react';
import {
    PageStates,
    PersonTypes,
    ApplicationRightSystemNames,
} from '../../services/SystemNames';
import { useNavigate } from 'react-router-dom';
import { DefaultCard } from './components/HomeCards';
import MetaIdbRepository from '../../repositories/idb/MetaIdbRepository';
import PubSub from 'pubsub-js';
import Overlay from '../../components/layout/overlay/Overlay';
import { isObjectNull, isNullOrEmpty } from '../../components/helpers/ObjectHelpers';
import { publishHeaderTopic, publishWarningNotificationTopic, PubSubTopics } from '../../components/helpers/PubSubHelpers';
import { hasApplicationRights, hasPersonType, isLoggedIn } from '../../components/helpers/AuthHelpers';
import PushPermissionModal from '../pilot/pushNotifications/PushPermissionModal';
import { refreshDeviceKey, updateSubscriptionToken } from '../pilot/pushNotifications/PushHelpers';
import { useGetApplicationRightsQuery, useGetSourceConfigQuery } from '../../reducers/slices/api.slice';
import { unsubscribe } from '../../firebase';

const initialState = {
    pageState: PageStates.Default,
    isBusy: true,
    hasErrors: false,
    name: "",
    isPilot: false,
    modules: [],
    showPushPermissionModal: false
};

function Index() {

    const navigate = useNavigate();
    const [
        {
            isBusy,
            hasErrors,
            name,
            modules,
            showPushPermissionModal
        }, setState] = useState(initialState);
    const [environment, setEnvironment] = useState("");

    const {
        data: applicationRights,
        isSuccess: isApplicationRightsSuccess
    } = useGetApplicationRightsQuery();

    const {
        data: sourceConfig,
        isSuccess: isSourceConfigSuccess
    } = useGetSourceConfigQuery();

    useEffect(() => {
        if (isNullOrEmpty(isApplicationRightsSuccess) || isNullOrEmpty(isSourceConfigSuccess)) return;
        if (navigator.onLine) {
            initializeOnlineAsync();
        } else {
            initializeOfflineAsync();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isApplicationRightsSuccess, isSourceConfigSuccess])


    // Initialize function
    useEffect(() => {
        publishHeaderTopic("NjordPilotWeb", "", false);
        PubSub.subscribe(PubSubTopics.Logout, handlePubSubTopic);

        if (navigator.onLine) {
            initializeOnlineAsync();
        } else {
            initializeOfflineAsync();
        }

        window.addEventListener('online', handleOnOffline);
        window.addEventListener('offline', handleOnOffline);

        return () => {
            window.removeEventListener('online', handleOnOffline);
            window.removeEventListener('offline', handleOnOffline);
            PubSub.unsubscribe(PubSubTopics.Logout);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <>
                {showPushPermissionModal && <PushPermissionModal />}
            </>
            <div className="home">
                <div className="welcome">
                    {!isNullOrEmpty(environment) && <DefaultCard subTitle={`Velkommen ${name}`} />}
                    <DefaultCard modules={modules} />
                </div>
            </div>

            <Overlay isBusy={isBusy} onReloadClick={onReloadClick} hasErrors={hasErrors} />
            
        </>
        
    );

    function shouldShowPushPermissionModal() {
        if (isLoggedIn() && !localStorage.getItem("shownAlready")) {
            try {
                if (Notification?.permission === "default" && !localStorage.getItem("shownAlready")) {
                    setState((prev) => ({
                        ...prev,
                        showPushPermissionModal: true
                    }));                    
                }                
            } catch (error) {
                console.error(error);            
            }
        }
    }

    function handleOnOffline() {
        initializeModules();
    }

    function onReloadClick() {
        setState((prev) => ({
            ...prev,
            hasErrors: false,
            isBusy: true
        }));

        initializeOnlineAsync();
    }

    function handlePubSubTopic() {
        // kommer man hit når man logger ut mens man er på forsiden?
        publishHeaderTopic("");

        setState((prev) => ({
            ...prev,
            name: "",
            modules: []
        }));

        setTimeout(() =>
            setState((prev) => ({
                ...prev,
                pageState: PageStates.Default
            })), 250);
    }

    async function initializeOnlineAsync() {

        setEnvironment(sourceConfig.environment);

        if (!isLoggedIn()) {
            setState((prev) => ({
                ...prev,
                pageState: PageStates.Default,
                isBusy: false
            }));
        } else {          
            shouldShowPushPermissionModal();

            initializeModules();

            const deviceId = localStorage.getItem("deviceId");
            if (deviceId) {
                refreshDeviceKey(deviceId);
                updateSubscriptionToken().then(() => localStorage.setItem("refreshed", true));
            }
                
            const whoAmI = await MetaIdbRepository.getWhoAmIAsync();

            setState((prev) => ({
                ...prev,
                name: whoAmI.firstName,
                pageState: PageStates.Welcome,
                isPilot: hasPersonType(PersonTypes.Pilot),
                isBusy: false
            }));
        }
    }

    async function initializeOfflineAsync() {

        if (isLoggedIn() === false) {
            setState((prev) => ({
                ...prev,
                pageState: PageStates.Default,
                isBusy: false
            }));

            //for push notifications
            localStorage.removeItem("refreshed")
            if (window?.Notification?.permission !== "granted") {
                localStorage.removeItem("shownAlready")
            }

        } else {
            initializeModules();

            const whoAmI = await MetaIdbRepository.getWhoAmIAsync();
            let firstName = "";

            if (!isObjectNull(whoAmI)) {
                firstName = whoAmI.firstName;
            } else {
                publishWarningNotificationTopic("Kunne ikke laste inn person informasjon");
            }
            setState((prev) => ({
                ...prev,
                isPilot: true,
                pageState: PageStates.Welcome,
                name: firstName,
                isBusy: false,
                hasErrors: false
            }));
        }
    }

    function initializeModules() {
        const modules = [];
        const isPilot = hasPersonType(PersonTypes.Pilot);

        if (isPilot) {
            if (navigator.onLine) {
                modules.push(
                    {
                        title: "Arbeidstid",
                        icon: "stopwatch",
                        onClick: () => navigate('/worktime/register'),
                        onlineOnly: true,
                        type: PersonTypes.Pilot,
                    }
                );
                modules.push(
                    {
                        title: "Mine oppdrag",
                        icon: "ship",
                        onClick: () => navigate('/pilot/pilotages/assigned'),
                        onlineOnly: false,
                        type: PersonTypes.Pilot,
                    }
                );
                modules.push(
                    {
                        title: "Alle oppdrag",
                        icon: "ship",
                        onClick: () => navigate('/pilotages/current'),
                        onlineOnly: false,  
                        type: PersonTypes.Pilot,
                    }
                );
                modules.push(
                    {
                        title: "Losoversikt",
                        icon: "pilotNotRelevant",
                        onClick: () => navigate('/pilot/station'),
                        onlineOnly: true,
                        type: PersonTypes.Pilot,
                    }
                );
            } else {
                modules.push(
                    {
                        title: "Mine oppdrag",
                        icon: "ship",
                        onClick: () => navigate('/pilot/pilotages/assigned'),
                        onlineOnly: false,
                        type: PersonTypes.Pilot,
                    }
                );
                modules.push({
                    title: "Evalueringer",
                    icon: "evaluation",
                    onClick: () => navigate('/evaluation'),
                    onlineOnly: false,
                    type: PersonTypes.Pilot,
                });
            }
        } else {
            if (navigator.onLine) {
                if (hasApplicationRights(applicationRights, [ApplicationRightSystemNames.EditPilotCoastalSegments])) {
                    modules.push(
                        {
                            title: "Sertifikat-omr&aring;der",
                            icon: "mapSegments",
                            onClick: () => navigate('/pcs/groups'),
                            onlineOnly: true
                        }
                    );
                }

                if (hasApplicationRights(applicationRights, [ApplicationRightSystemNames.EditPilotCoastalSegmentsEvaluation])) {
                    modules.push(
                        {
                            title: "S.O. Evalueringer",
                            icon: "mapSegments",
                            onClick: () => navigate('/pcs/evaluations'),
                            onlineOnly: true
                        }
                    );
                }

                if (hasApplicationRights(applicationRights, [ApplicationRightSystemNames.EditPilotCoastalSegmentsLocations])) {
                    modules.push(
                        {
                            title: "S.O. Lokasjoner",
                            icon: "mapSegments",
                            onClick: () => navigate('/pcs/locations'),
                            onlineOnly: true
                        }
                    );
                }

                modules.push(
                    {
                        title: "Alle oppdrag",
                        icon: "ship",
                        onClick: () => navigate('/pilotages/current'),
                        onlineOnly: true,
                    }
                );
            }
        }

        setState((prev) => ({
            ...prev,
            modules: modules
        }));
    }
}

export default Index;
