import PilotAssignmentIdbRepository from "../../../../repositories/idb/PilotAssignmentIdbRepository";
import { IconColors, PageStates, PilotAssignmentStatusColors, PilotAssignmentStatusEnums } from "../../../../services/SystemNames";
import Moment from 'moment';
import uuid from 'react-uuid';
import hexRgb from 'hex-rgb';
import parse from 'html-react-parser';
import { deepCopyObject, isArrayEmpty, isNullOrEmpty, isObjectNull } from "../../../../components/helpers/ObjectHelpers";
import { addPilotArrivalTimeCommand, addUpdateLocationArrivalTimeCommand, addUpdateLocationBoardingTypeCommand, addUpdateLocationDepartureTimeCommand, addUpdateLocationPilotTypeCommand, getCompletePilotageReceiptCommand, hasCommandType } from "../helpers/PilotAssignmentCommandHelpers";
import { getFormattedDateTime } from "../../../../components/helpers/DateTimeHelpers";
import PilotagePilotTypeIdbRepository from "../../../../repositories/idb/PilotagePilotTypeIdbRepository";
import { PilotAssignmentCommands } from "../../services/DispatcherActions";
import MetaIdbRepository from "../../../../repositories/idb/MetaIdbRepository";

async function getPilotAssignmentAsync(pilotAssignmentId) {
    return await PilotAssignmentIdbRepository.getAsync(pilotAssignmentId);
}

function createEmptyPilotAssignment() {
    return {
        pilotage: {
            pilotageDetail: {
                fromTime: Moment()
            },
            pilotageShip: {},
            fromLocation: {},
            toLocation: {},
            pilotagePilotsInformation: []
        },
        pilotagePilotStatus: {}
    };
}

function convertApiDtoToIdb(dto) {
    dto.guid = uuid();
    dto.hasExternalChanges = false;
    dto.wasServerUpdated = false;
    dto.commands = [];

    dto.original = {
        locations: [],
        hourCompensations: [],
        overtimes: [],
        variableCompensations: [],

        arrivalTime: null,
        departureTime: null,
        returnTime: null,
        isWastedTrip: false,
        waitingHoursReasonRemark: "",
        remark: "",
        isReimbursementIncluded: false
    }

    if (isNullOrEmpty(dto.remark)) {
        dto.remark = "";
    }

    if (isNullOrEmpty(dto.waitingHoursReasonRemark)) {
        dto.waitingHoursReasonRemark = "";
    }

    if (isObjectNull(dto.locations)) {
        dto.locations = [];
    } else {
        dto.original.locations = deepCopyObject(dto.locations);
    }

    if (isObjectNull(dto.pilotagePilotCompensation.pilotagePilotHourCompensations)) {
        dto.pilotagePilotCompensation.pilotagePilotHourCompensations = [];
    } else {
        dto.original.hourCompensations = deepCopyObject(dto.pilotagePilotCompensation.pilotagePilotHourCompensations);
    }

    if (isObjectNull(dto.pilotagePilotCompensation.pilotagePilotOvertimes)) {
        dto.pilotagePilotCompensation.pilotagePilotOvertimes = [];
    } else {
        dto.original.overtimes = deepCopyObject(dto.pilotagePilotCompensation.pilotagePilotOvertimes);
    }
    if (!isObjectNull(dto.pilotagePilotCompensation.pilotagePilotVariableCompensations)) {
        dto.original.variableCompensations = deepCopyObject(dto.pilotagePilotCompensation.pilotagePilotVariableCompensations);
    }

    dto.original.arrivalTime = dto.arrivalTime;
    dto.original.departureTime = dto.departureTime;
    dto.original.returnTime = dto.returnTime;
    dto.original.isWastedTrip = dto.isWastedTrip;
    dto.original.waitingHoursReasonRemark = dto.waitingHoursReasonRemark;
    dto.original.remark = dto.remark;
    dto.original.isReimbursementIncluded = dto.pilotagePilotCompensation.isReimbursementIncluded;

    return dto;
}

function convertExecutedApiDtoToIdb(dto) {
    return {
        pilotagePilotId: dto.pilotagePilotId,
        hasLocalChanges: false,
        hasExternalChanges: false,
        pilotagePilotStatus: dto.pilotagePilotStatus,
        isTrainee: dto.isTrainee,
        isExaminer: dto.isExaminer,
        isConfirmedByPilotDispatcher: dto.isConfirmedByPilotDispatcher,
        pilotage: {
            pilotageId: dto.pilotageId,
            pilotageNo: dto.pilotageNo,
            pilotageShip: {
                shipName: dto.shipName
            },
            fromLocation: {
                name: dto.fromLocationName
            },
            toLocation: {
                name: dto.toLocationName
            },
            pilotageDetail: {
                fromTime: dto.fromTime,
                isLocked: dto.isLocked
            },
            hasObservation: dto.hasObservation
        }
    }
}

function isTentative(pilotAssignment) {
    return !pilotAssignment.isConfirmedByPilotDispatcher &&
        !pilotAssignment.isExaminer &&
        !pilotAssignment.isTrainee;
}

function isAssignedAndNotProcessed(pilotAssignment) {
    if (isTentative(pilotAssignment)) return false;

    switch (pilotAssignment.pilotagePilotStatus.systemName) {
        case PilotAssignmentStatusEnums.Confirmed:
        case PilotAssignmentStatusEnums.Rejected:
        case PilotAssignmentStatusEnums.Completed:
        case PilotAssignmentStatusEnums.CorrectionNeeded:
            return false;
        default:
            return true;
    }
}

function isCompleted(pilotAssignment) {
    if (isTentative(pilotAssignment)) return false;
    if (isNullOrEmpty(pilotAssignment.pilotagePilotStatus)) return true;

    switch (pilotAssignment.pilotagePilotStatus.systemName) {
        case PilotAssignmentStatusEnums.Completed:
            return true;
        default:
            return false;
    }
}

function isCorrectionNeeded(pilotAssignment) {
    return pilotAssignment.pilotagePilotStatus.systemName === PilotAssignmentStatusEnums.CorrectionNeeded;
}

function isConfirmed(pilotAssignment) {
    if (isTentative(pilotAssignment)) return false;
    switch (pilotAssignment.pilotagePilotStatus.systemName) {
        case PilotAssignmentStatusEnums.Confirmed:
            return true;
        default:
            return false;
    }
}

function isReadyForInvoicing(pilotAssignment) {
    return pilotAssignment.isReadyForInvoicing
}

function isExaminerOnly(pilotAssignment) {
    if (isObjectNull(pilotAssignment)) return false;

    const examinerOnly = pilotAssignment.isExaminer && !pilotAssignment.isTrainee && !pilotAssignment.isConfirmedByPilotDispatcher;

    if (examinerOnly) {
        return !hasCommandType(pilotAssignment, PilotAssignmentCommands.ConvertToPilotageIncl);
    }
    return false;
}

function isTrainee(pilotAssignment) {
    if (isObjectNull(pilotAssignment)) return false;
    return pilotAssignment.isTrainee;
}

function hasInvoice(pilotAssignment) {
    return pilotAssignment.pilotage.containsInvoices;
}

function hasPecExams(pilotAssignment) {
    return !isArrayEmpty(pilotAssignment.pilotage.pecExams);
}

function hasExaminers(pilotAssignment) {
    const examiners = pilotAssignment.pilotage.pilotagePilotsInformation.filter(p => p.isExaminer === true);
    return !isArrayEmpty(examiners);
}

function getLocationBySequenceNo(locations, sequenceNo) {
    return locations.find(l => l.sequenceNo === sequenceNo);
}

function hasMultiplePilotsConfirmedByPilotDispatcher(pilotAssignment) {

    if (isObjectNull(pilotAssignment)) return false;

    const pilots = pilotAssignment
        .pilotage
        .pilotagePilotsInformation
        .filter(p => p.isConfirmedByPilotDispatcher && p.pilotagePilotStatus.systemName !== PilotAssignmentStatusEnums.Rejected);

    if (isObjectNull(pilots)) return false;

    return pilots.length > 1;
}

function getPilotageStartTime(pilotAssignment) {
    const locations = pilotAssignment.locations;
    if (isArrayEmpty(locations)) return null;

    const sortedLocations =
        sortLocationsBySequenceNo(locations)
            .filter(l => !isNullOrEmpty(l.fromTime));

    if (isArrayEmpty(sortedLocations)) return null;

    return Moment(sortedLocations[0].fromTime).format('YYYY-MM-DDTHH:mm:00');
}

function getPilotageEndTime(pilotAssignment) {
    const locations = pilotAssignment.locations;
    if (isArrayEmpty(locations)) return null;

    const sortedLocations =
        sortLocationsBySequenceNo(locations)
            .filter(l => !isNullOrEmpty(l.toTime));

    if (isArrayEmpty(sortedLocations)) return null;

    return Moment(sortedLocations[sortedLocations.length - 1].toTime).format('YYYY-MM-DDTHH:mm:00');
}

function sortLocationsBySequenceNo(locations) {
    return locations
        .sort((a, b) => {
            if (a.sequenceNo > b.sequenceNo) return 1;
            return -1;
        });
}

function getPilotAssignmentUrl(pilotAssignment) {
    return `/pilot/pilotage/${pilotAssignment.pilotage.pilotageId}`;
}

function getStatusColorRgba(pilotAssignment) {
    if (!pilotAssignment) return;
    var hex = getStatusColor(pilotAssignment);
    var rgba = hexRgb(hex);
    return `rgba(${rgba.red}, ${rgba.green}, ${rgba.blue}, 0.25)`;
}

function getStatusColorRgbaBySystemName(systemName) {
    var hex = getStatusColorBySystemName(systemName);
    var rgba = hexRgb(hex);
    return `rgba(${rgba.red}, ${rgba.green}, ${rgba.blue}, 0.25)`;
}

function getStatusColor(pilotAssignment) {

    if (isTentative(pilotAssignment)) return PilotAssignmentStatusColors.Tentative;

    return getStatusColorBySystemName(pilotAssignment.pilotagePilotStatus.systemName);
}

function getStatusColorBySystemName(systemName) {
    switch (systemName) {
        case PilotAssignmentStatusEnums.Confirmed:
            return PilotAssignmentStatusColors.Confirmed;
        case PilotAssignmentStatusEnums.Rejected:
            return PilotAssignmentStatusColors.Rejected;
        case PilotAssignmentStatusEnums.Completed:
            return PilotAssignmentStatusColors.Completed;
        case PilotAssignmentStatusEnums.CorrectionNeeded:
            return PilotAssignmentStatusColors.CorrectionNeeded;
        default:
            return PilotAssignmentStatusColors.Assigned;
    }
}

function getFromAndToTime(locations) {
    let fromTime = null;
    let toTime = null;

    for (let i = 0; i < locations.length; i++) {
        const locationFromTime = locations[i].fromTime;
        const locationToTime = locations[i].toTime;

        // FromTime
        if (!isNullOrEmpty(locationFromTime)) {
            if (isNullOrEmpty(fromTime) || Moment(locationFromTime) < Moment(fromTime)) {
                fromTime = locationFromTime;
            }
        }

        // ToTime
        if (!isNullOrEmpty(locationToTime)) {
            if (isNullOrEmpty(toTime) || Moment(locationToTime) > Moment(toTime)) {
                toTime = locationToTime;
            }
        }
    }

    return { fromTime: fromTime, toTime: toTime }
}

async function getMinutesOnHold(locations) {
    let minutesOnHoldDay = 0;
    let minutesOnHoldNight = 0;

    const dayTime = await MetaIdbRepository.getDayTimeAsync();
    const dayStartHour = Moment(`2000-01-01T${dayTime.dayStart}`).hour();
    const dayEndHour = Moment(`2000-01-01T${dayTime.dayEnd}`).hour();

    for (let i = 1; i < locations.length - 1; i++) {
        const locationFromTime = locations[i].fromTime;
        const locationToTime = locations[i].toTime;

        let dayTimeMinutes = 0;
        let nightTimeMinutes = 0;

        if (!isNullOrEmpty(locationFromTime) && !isNullOrEmpty(locationToTime)) {

            const diffMinutes = Moment(locationFromTime).diff(Moment(locationToTime), 'minutes');
            let from = Moment(locationToTime);

            for (let i = 1; i <= diffMinutes; i++) {
                const hour = from.hour();
                if (hour >= dayStartHour && hour < dayEndHour) {
                    dayTimeMinutes += 1;
                } else {
                    nightTimeMinutes += 1;
                }
                from = from.add(1, 'm');
            }
        }
        minutesOnHoldDay += dayTimeMinutes;
        minutesOnHoldNight += nightTimeMinutes
    }

    return {
        minutesOnHoldDay: minutesOnHoldDay,
        minutesOnHoldNight: minutesOnHoldNight
    };
}

function getBottomMenuItems(pilotAssignment) {
    if (isNullOrEmpty(pilotAssignment.pilotagePilotId)) return [];

    const items = [
        {
            text: "Info",
            icon: "information",
            action: PageStates.Default
        },
        {
            text: parse("Fart&oslash;y"),
            icon: "ship",
            action: PageStates.Ship
        },
    ];

    if (
        (
            isConfirmed(pilotAssignment) ||
            isCorrectionNeeded(pilotAssignment) ||
            isCompleted(pilotAssignment)
        )
    ) {

        items.push({
            text: "Sk.bevis",
            icon: "document",
            action: PageStates.PilotAssignment
        });
    }

    if (navigator.onLine) {
        // todo: show on next planning
        //items.push({
        //    text: "Observ.",
        //    icon: "eye",
        //    action: PageStates.Observations
        //});
        items.push({
            text: "Kart",
            icon: "map",
            action: PageStates.Map
        });
    }

    return items;
}

function getIconColor(pilotagePilot) {
    switch (pilotagePilot.pilotagePilotStatus.systemName) {
        case "CONFIRMED":
            return IconColors.Secondary;
        default:
            return IconColors.Primary;
    }
}

function createPilotsShortList(pilotagePilots) {
    const result = [];
    for (const pilotagePilot of pilotagePilots) {
        result.push({
            borderColor: getStatusColor(pilotagePilot),
            text: pilotagePilot.initials,
            isExaminer: pilotagePilot.isExaminer,
            isTrainee: pilotagePilot.isTrainee
        });
    }
    return result;
}

function isEditable(pilotAssignment) {
    return ((isConfirmed(pilotAssignment) ||
        isCorrectionNeeded(pilotAssignment)) && isObjectNull(getCompletePilotageReceiptCommand(pilotAssignment)));
}

function getFilteredCompensations(compensations, obj, propertyName) {
    let filteredCompensations = [];

    let filterByPropertyName = false;

    if (obj.hasOwnProperty(propertyName)) {
        filterByPropertyName = obj[propertyName] > 0;
    }

    if (filterByPropertyName) {
        filteredCompensations = compensations.filter(c => c[propertyName] !== obj[propertyName]);
    } else {
        filteredCompensations = compensations.filter(c => c.guid !== obj.guid);
    }

    return filteredCompensations;
}

function toggleSelectedItem(selectedItems, id, selected) {
    const newItem = { id: id, selected: selected };
    let result = deepCopyObject(selectedItems);
    if (!result.some(c => c.id === id)) {
        result.push(newItem);
    } else {
        result = result.map((item) =>
            item.id === id ? { ...item, ...newItem } : item
        );
    }

    return result;
}

function getCompensationId(compensation, propertyName) {
    if (compensation.hasOwnProperty(propertyName)) return compensation[propertyName];
    if (compensation.hasOwnProperty("guid")) return compensation.guid;
    return 0;
}

function isLocationAtSea(location) {

    if (location.hasOwnProperty("locationType")) {
        return isLocationSystemNameAtSea(location.locationType.systemName);
    }

    if (location.hasOwnProperty("systemName")) {
        return isLocationSystemNameAtSea(location.systemName);
    }

    return false;
}

function isLocationSystemNameAtSea(systemName) {
    return systemName === "PILOT_BOARDING" ||
        systemName === "ANCHORAGE" ||
        systemName === "LOCATION_AT_SEA";
}

function isCompensationEqual(originalArr, updatedArr, systemName) {

    if (isObjectNull(updatedArr) || isObjectNull(originalArr)) return false;

    let original = getCompensationBySystemName(originalArr, systemName);
    let updated = getCompensationBySystemName(updatedArr, systemName);

    return updated.overridenNumber === original.overridenNumber;
}

function isPilotageIncl(pilotAssignment) {
    return pilotAssignment.pilotage.pilotageOrderType.systemName === "PILOT_INCL";
}

function getCompensationBySystemName(array, systemName) {
    return array.find(x => x.pilotagePilotVariableCompensationSystemName === systemName);
}

async function setSuggestions(pilotageId) {

    let pilotAssignment = await PilotAssignmentIdbRepository.getByPilotageIdAsync(pilotageId);

    if (!isEditable(pilotAssignment)) return;
    
    const isHelicopterRequired = pilotAssignment.pilotage.isHelicopterRequired;
    const pilotCount = pilotAssignment.pilotage.pilotagePilotsInformation.length;
    const isMoreThanOnePilotRequired = pilotAssignment.pilotage.isMoreThanOnePilotRequired;

    if (isNullOrEmpty(pilotAssignment.arrivalTime)) {
        const arrivalTime = pilotAssignment.pilotage.pilotageDetail.fromTime;
        pilotAssignment.arrivalTime = getFormattedDateTime(arrivalTime);
        pilotAssignment = addPilotArrivalTimeCommand(pilotAssignment, arrivalTime);
    }

    for (const location of pilotAssignment.locations) {
        const index = pilotAssignment.locations.indexOf(location);
        const isFirst = index === 0;
        const isLast = index === pilotAssignment.locations.length - 1;
        
        const boardingOnly = isFirst;
        const offBoardingOnly = isLast;

        if (isObjectNull(location.pilotBoardingType)) {
            if ((isFirst || isLast) && isLocationAtSea(location)) {
                const boardingType = {
                    isPilotBoardingTypeOffBoarding: false,
                    systemName: isHelicopterRequired ? "HELICOPTER" : "PILOT_BOAT"
                };

                if (offBoardingOnly || (!boardingOnly && !offBoardingOnly)) {
                    boardingType.isPilotBoardingTypeOffBoarding = true;
                }
                
                location.pilotBoardingType = boardingType;
                location.isPilotBoardingTypeOffBoarding = boardingType.isPilotBoardingTypeOffBoarding;

                pilotAssignment = addUpdateLocationBoardingTypeCommand(pilotAssignment,
                    {
                        location: location,
                        boardingType: boardingType
                    });
            }
        }


        if (location.pilotTypeId === 0) {
            let dto = null;
            if (location.sequenceNo === 2) {
                if (pilotCount === 1) {
                    dto = await PilotagePilotTypeIdbRepository.getDefaultAsync();
                } else if (hasMultiplePilotsConfirmedByPilotDispatcher(pilotAssignment)) {
                    if (isMoreThanOnePilotRequired) {
                        dto = await PilotagePilotTypeIdbRepository.getBySystemNameAsync("PARALLEL_PILOTING");
                    } else {
                        dto = await PilotagePilotTypeIdbRepository.getBySystemNameAsync("SEQUENTIAL_PILOTING");
                    }
                }
            }

            if (!isNullOrEmpty(dto)) {
                location.pilotType = dto.pilotType;
                location.pilotTypeId = dto.pilotagePilotTypeId;
                pilotAssignment = addUpdateLocationPilotTypeCommand(pilotAssignment,
                    {
                        location: location,
                        pilotType: dto
                    });
            }
        }

        if (isFirst && isNullOrEmpty(location.fromTime)) {
            const startTimeObj = {
                location: location,
                departureTime: pilotAssignment.pilotage.pilotageDetail.fromTime,
                isPilotBoardingTypeOffBoarding: !isFirst
            }

            location.fromTime = getFormattedDateTime(startTimeObj.departureTime);
            pilotAssignment = addUpdateLocationDepartureTimeCommand(pilotAssignment, startTimeObj);
        }

        const pilotageEstimatedDurationInMinutes = pilotAssignment.pilotage.pilotageDetail.pilotageEstimatedDurationInMinutes;
        if (isLast && isNullOrEmpty(location.toTime) && !isNullOrEmpty(pilotageEstimatedDurationInMinutes)) {
            const toTime = Moment(pilotAssignment.pilotage.pilotageDetail.fromTime).add(pilotageEstimatedDurationInMinutes, 'minutes');

            const stopTimeObj = {
                location: location,
                arrivalTime: toTime.toDate(),
                isPilotBoardingTypeOffBoarding: !isFirst
            }

            location.toTime = getFormattedDateTime(stopTimeObj.arrivalTime);

            pilotAssignment = addUpdateLocationArrivalTimeCommand(pilotAssignment, stopTimeObj);
        }
    }

    await PilotAssignmentIdbRepository.setAsync(pilotAssignment); 
}

function getTransportOrderBorderColor(pilotTransportOrderStatus) {
    switch (pilotTransportOrderStatus) {
        case "ORDERED":
            return "var(--pto-status-ordered-boarder)";
        case "CONFIRMED":
            return "var(--pto-status-confirmed-boarder)";
        case "CANCELLED":
            return "var(--pto-status-cancelled-boarder)";
        default:
            return "var(--pto-status-allocated-boarder)";
    }
}

function getTransportOrderBackgroundColor(pilotTransportOrderStatus) {
    switch (pilotTransportOrderStatus) {
        case "ORDERED":
            return "var(--pto-status-ordered-bg)";
        case "CONFIRMED":
            return "var(--pto-status-confirmed-bg)";
        case "CANCELLED":
            return "var(--pto-status-cancelled-bg)";
        default:
            return "var(--pto-status-allocated-bg)";
    }
}

export {
    getPilotAssignmentAsync,
    createEmptyPilotAssignment,
    isLocationAtSea,
    toggleSelectedItem,
    sortLocationsBySequenceNo,
    hasMultiplePilotsConfirmedByPilotDispatcher,

    convertApiDtoToIdb as convertPilotAssignmentApiDtoToIdb,
    convertExecutedApiDtoToIdb as convertExecutedPilotAssignmentApiDtoToIdb,
    getStatusColorRgba as getPilotAssignmentStatusColorRgba,
    getStatusColorRgbaBySystemName as getPilotAssignmentStatusColorRgbaBySystemName,
    getStatusColor as getPilotAssignmentStatusColor,
    getStatusColorBySystemName as getPilotAssignmentStatusColorBySystemName,
    isEditable as isPilotAssignmentEditable,
    isAssignedAndNotProcessed as isPilotAssignmentAssignedAndNotProcessed,
    isCorrectionNeeded as isPilotAssignmentCorrectionNeeded,
    isCompleted as isPilotAssignmentCompleted,
    isConfirmed as isPilotAssignmentConfirmed,
    isTentative as isPilotAssignmentTentative,
    isExaminerOnly as isPilotAssignmentExaminerOnly,
    isTrainee as isPilotAssignmentTrainee,
    hasInvoice as hasPilotAssignmentInvoice,
    hasPecExams as hasPilotAssignmentPecExams,
    hasExaminers as hasPilotAssignmentExaminers,
    getPilotAssignmentUrl,
    getBottomMenuItems as getPilotAssignmentBottomMenuItems,
    createPilotsShortList as createPilotAssignmentPilotsShortList,
    getFromAndToTime as getPilotAssignmentFromAndToTime,
    getCompensationId as getPilotAssignmentCompensationId,
    getFilteredCompensations as getPilotAssignmentFilteredCompensations,
    getIconColor as getPilotAssignmentIconColor,
    getLocationBySequenceNo as getPilotAssignmentLocationBySequenceNo,
    isCompensationEqual as isPilotAssignmentCompensationEqual,
    isPilotageIncl as isPilotAssignmentPilotageIncl,
    getPilotageStartTime as getPilotAssignmentPilotageStartTime,
    getPilotageEndTime as getPilotAssignmentPilotageEndTime,
    setSuggestions as setPilotAssignmentSuggestions,
    getTransportOrderBorderColor as getPilotAssignmentTransportOrderBorderColor,
    getTransportOrderBackgroundColor as getPilotAssignmentTransportOrderBackgroundColor,
    isReadyForInvoicing as isPilotAssignmentReadyForInvoicing,
    getMinutesOnHold as getMinutesOnHold
}
