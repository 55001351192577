import React, { forwardRef, useEffect, useImperativeHandle, useReducer, useState } from 'react';
import { PilotAssignmentCommands, PilotAssignmentErrorCodes } from '../../services/DispatcherActions';
import PilotagePilotTypeIdbRepository from '../../../../repositories/idb/PilotagePilotTypeIdbRepository';
import PilotBoardingTypeIdbRepository from '../../../../repositories/idb/PilotBoardingTypeIdbRepository';
import Spacer from '../../../../components/layout/Spacer';
import parse from 'html-react-parser';
import { PilotBoardingTypeEnums } from '../../../../services/SystemNames';
import DateTimePicker, { DateTimePickerModes } from '../../../../components/layout/DateTimePicker';
import {
    FieldWrapper,
} from "@progress/kendo-react-form";
import { Card } from '../../../../components/layout/card/Card';
import uuid from 'react-uuid';
import { Dialog } from '../../../../components/layout/dialogs/Dialog';
import { CardActionTypes, CardProperties } from '../../../../components/layout/card/components/CardProperties';
import { Label } from '../../../../components/layout/Label';
import { DialogProperties } from '../../../../components/layout/dialogs/DialogProperties';
import SelectOptionFieldWrapper from '../../../../components/layout/fieldWrappers/SelectOptionFieldWrapper';
import { errorsContainsFromObject, isArrayEmpty, isNullOrEmpty, isObjectNull } from '../../../../components/helpers/ObjectHelpers';
import { hasAddLocationCommand, hasLocationCommand } from '../helpers/PilotAssignmentCommandHelpers';
import { MapPinPlus } from "@phosphor-icons/react";
import PubSub from 'pubsub-js';
import { PubSubTopics } from '../../../../components/helpers/PubSubHelpers';


export const dispatcherActions = {
    PilotType: 'PilotType',
    BoardingType: 'BoardingType',
}

export const LocationCard = forwardRef((
    {
        locations,
        location,
        sourcePilotAssignment,
        isEditable = false,
        isFirst = false,
        isLast = false,
        handleCommands,
        iconColor = "",
        borderColor = "",
        backgroundColor = "",
        codeStrings = []
    }, ref) => {

    useImperativeHandle(ref, () => ({
        onLocationsChanged(pilotAssignment) {
            setLocationPilotAssignment(pilotAssignment);
            initializeAsync(pilotAssignment);
        },
    }));

    useEffect(() => {
            setHasStopTimeValidationError(errorsContainsFromObject(codeStrings, stopTimeTargetErrorCodes));
            setHasStartTimeValidationError(errorsContainsFromObject(codeStrings, startTimeTargetErrorCodes));
            setHasBoardingTypeValidationError(errorsContainsFromObject(codeStrings, boardingTypeTargetErrorCodes));
            setHasPilotTypeValidationError(errorsContainsFromObject(codeStrings, pilotTypeTargetErrorCodes));
    }, [codeStrings])

    const initialState = {
        pilotType: "",
        boardingType: "",
    }

    const [locationPilotAssignment, setLocationPilotAssignment] = useState(sourcePilotAssignment);
    const [showDialog, setShowDialog] = useState(false);
    const [hasPilotTypeValidationError, setHasPilotTypeValidationError] = useState(false);
    const [hasStopTimeValidationError, setHasStopTimeValidationError] = useState(false);
    const [hasStartTimeValidationError, setHasStartTimeValidationError] = useState(false);
    const [hasBoardingTypeValidationError, setHasBoardingTypeValidationError] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const [isPilotTypeDirty, setIsPilotTypeDirty] = useState(false);

    const startTimeTargetErrorCodes = {
        PilotLocationArrivalTimeExistsPreviousDepartureTimeIsMissing: `${PilotAssignmentErrorCodes.PilotLocationArrivalTimeExistsPreviousDepartureTimeIsMissing}_${location.sequenceNo}`,
        PilotLocationArrivalTimeCannotBeInTheFuture: `${PilotAssignmentErrorCodes.PilotLocationArrivalTimeCannotBeInTheFuture}_${location.sequenceNo}`
    };

    const stopTimeTargetErrorCodes = {
        PilotLocationPrevousArrivalTimeExistsDepartureTimeIsMissing: `${PilotAssignmentErrorCodes.PilotLocationPrevousArrivalTimeExistsDepartureTimeIsMissing}_${location.sequenceNo}`,
        PilotLocationDepartureTimeMustBeAfterPreviousDepartureTime: `${PilotAssignmentErrorCodes.PilotLocationDepartureTimeMustBeAfterPreviousDepartureTime}_${location.sequenceNo}`,
        PilotLocationDepartureTimeMustBeAfterPreviousArrivalTime: `${PilotAssignmentErrorCodes.PilotLocationDepartureTimeMustBeAfterPreviousArrivalTime}_${location.sequenceNo}`,
        PilotLocationDepartureTimeCannotBeInTheFuture: `${PilotAssignmentErrorCodes.PilotLocationDepartureTimeCannotBeInTheFuture}_${location.sequenceNo}`
    };

    const boardingTypeTargetErrorCodes = {
        PilotLocationOffboardingNotPossible: `${PilotAssignmentErrorCodes.PilotLocationOffboardingNotPossible}_${location.sequenceNo}`
    };

    const pilotTypeTargetErrorCodes = {
        PilotLocationPilotTypeIsMissing: `${PilotAssignmentErrorCodes.PilotLocationPilotTypeIsMissing}_${location.sequenceNo}`,
    };

    const reducer = (state = initialState, action) => {
        switch (action.type) {

            case dispatcherActions.PilotType:
                return Object.assign({}, state, {
                    pilotType: action.payload
                });

            case dispatcherActions.BoardingType:
                return Object.assign({}, state, {
                    boardingType: action.payload
                });

            default:
                return state;
        }
    };

    const [state, stateDispatch] = useReducer(reducer, initialState);
    const dispatch = (type, payload = null) => {
        stateDispatch({ type: type, payload: payload });
    }

    useEffect(() => {
        PubSub.subscribe(PubSubTopics.PilotAssignmentIsEdibleChanged, initializeAsync(sourcePilotAssignment))
        initializeAsync(sourcePilotAssignment);
        // eslint-disable-next-line react-hooks/exhaustive-deps

        return () => {
            PubSub.unsubscribe(PubSubTopics.PilotAssignmentIsEdibleChanged);
        };
    }, []);

    return (
        <>
            {
                !isFirst &&
                <>
                    <Spacer height={30} />
                    <LocationPilotTypeAddLocationCard
                        handleCommands={handleCommands}
                        location={location}
                        pilotAssignment={locationPilotAssignment}
                        locations={locations}
                        isEditable={isEditable}
                        hasPilotTypeValidationError={hasPilotTypeValidationError}
                        onSearchLocationsClick={onSearchLocationsClick}
                        isDirty={isPilotTypeDirty}
                    />
                    <Spacer height={30} />
                </>
            }
            <Card
                properties={{
                    ...CardProperties,
                    title: location.name,
                    className: "card-item-content-border-left",
                    hasValidationError: (hasStopTimeValidationError || hasStartTimeValidationError || hasBoardingTypeValidationError),
                    borderColor: borderColor,
                    isBorderLeftFat: true,
                    isDirty: isDirty,
                    actions: (!isFirst && !isLast && isEditable) ?
                        [
                            {
                                icon: "trash",
                                iconColor: iconColor,
                                type: CardActionTypes.Delete,
                                onClick: () => setShowDialog(true),
                                hideOnSwipeLeft: true
                            }
                        ] : []
                }}>
                <div className={`pp-location-details-card-body`}>

                    <div className="pp-time-1">
                        {
                            isFirst ?
                                <LocationStartStopCard
                                    isEditable={isEditable}
                                    title="Start"
                                    subTitle="Velg start tid"
                                    time={location.fromTime}
                                    hasValidationError={hasStartTimeValidationError}
                                    onChange={onDateStartTimePickerChanged}
                                />
                                :
                                <LocationStartStopCard
                                    isEditable={isEditable}
                                    title="Stopp"
                                    subTitle="Velg stopp tid"
                                    time={location.toTime}
                                    show={state.showStopDateTimePicker}
                                    hasValidationError={hasStopTimeValidationError}
                                    onChange={onDateStopTimePickerChanged}
                                />
                        }
                    </div>

                    {
                        (!isFirst && !isLast) &&
                        <div className="pp-time-2">
                            <LocationStartStopCard
                                isEditable={isEditable}
                                title="Start"
                                subTitle="Velg start tid"
                                time={location.fromTime}
                                hasValidationError={hasStartTimeValidationError}
                                onChange={onDateStartTimePickerChanged}
                            />
                        </div>
                    }

                    <div className="pp-boarding-type">
                        <LocationBoardingTypeCard
                            location={location}
                            isFirst={isFirst}
                            isLast={isLast}
                            handleCommands={handleCommands}
                            isEditable={isEditable}
                            hasValidationError={hasBoardingTypeValidationError}
                            isHelicopterRequired={locationPilotAssignment.pilotage.isHelicopterRequired}
                        />
                    </div>

                </div>
            </Card>

            {
                showDialog &&
                <Dialog
                    properties={{
                        ...DialogProperties,
                        mode: "confirmation",
                        onClose: () => setShowDialog(false),
                        onClick: onConfirmDelete,
                        title: "Fjern lokasjon",
                        text: `&Oslash;nsker du &aring; fjerne lokasjonen <b>${location.name}</b>`
                    }}

                />
            }

        </>
    )

    async function initializeAsync(pilotAssignment) {
        initializeDirty(pilotAssignment);

        await initializePilotTypeAsync();
        await initializeBoardingTypeAsync();
    }

    function initializeDirty(pilotAssignment) {
        if (isArrayEmpty(pilotAssignment.commands)) {
            setIsDirty(false);
            setIsPilotTypeDirty(false);
            return;
        }

        const hasAddLocationCmd = hasAddLocationCommand(pilotAssignment, location);
        const hasArrivalTimeCommand = hasLocationCommand(pilotAssignment, location, PilotAssignmentCommands.UpdateLocationArrivalTime);
        const hasDepartureTimeCommand = hasLocationCommand(pilotAssignment, location, PilotAssignmentCommands.UpdateLocationDepartureTime);
        const hasBoardingTypeCommand = hasLocationCommand(pilotAssignment, location, PilotAssignmentCommands.UpdateLocationBoardingType);
        const hasPilotTypeCommand = hasLocationCommand(pilotAssignment, location, PilotAssignmentCommands.UpdateLocationPilotType);

        setIsPilotTypeDirty(hasPilotTypeCommand);

        if (isFirst) {
            setIsDirty(hasDepartureTimeCommand || hasBoardingTypeCommand);
        } else if (isLast) {
            setIsDirty(hasArrivalTimeCommand || hasBoardingTypeCommand);
        } else {
            setIsDirty(hasAddLocationCmd || hasDepartureTimeCommand || hasArrivalTimeCommand || hasBoardingTypeCommand);
        }
    }

    async function initializeBoardingTypeAsync() {

        const defaultBoardingType = await PilotBoardingTypeIdbRepository.getDefaultAsync();

        if (isObjectNull(location.pilotBoardingType)) {
            dispatch(dispatcherActions.BoardingType, defaultBoardingType.name);
        } else {
            const title = location.isPilotBoardingTypeOffBoarding ? "Kvitting" : "Bording";
            const dto = await PilotBoardingTypeIdbRepository.getBySystemNameAsync(location.pilotBoardingType.systemName);
            if (location.pilotBoardingType.systemName === PilotBoardingTypeEnums.NotApplicable) {
                dispatch(dispatcherActions.BoardingType, dto.name);
            } else {
                dispatch(dispatcherActions.BoardingType, `${title} ${dto.name}`);
            }
        }
    }

    async function initializePilotTypeAsync() {
        if (isNullOrEmpty(location.pilotTypeId)) {
            dispatch(dispatcherActions.PilotType, "Ikke satt");
        } else {
            const dto = await PilotagePilotTypeIdbRepository.getAsync(location.pilotTypeId);
            if (!isObjectNull(dto)) {
                dispatch(dispatcherActions.PilotType, dto.name);
            } else {
                dispatch(dispatcherActions.PilotType, "Ikke satt");
            }
        }
    }

    function onDateStartTimePickerChanged(event) {
        if (!isEditable) return;
        const obj = {
            location: location,
            departureTime: event.value,
            isPilotBoardingTypeOffBoarding: !isFirst
        }
        handleCommands(PilotAssignmentCommands.UpdateLocationDepartureTime, obj);
        dispatch(dispatcherActions.ShowStartDateTimePicker, false);
    }

    function onDateStopTimePickerChanged(event) {
        if (!isEditable) return;
        const obj = {
            location: location,
            arrivalTime: event.value,
            isPilotBoardingTypeOffBoarding: !isFirst
        }
        handleCommands(PilotAssignmentCommands.UpdateLocationArrivalTime, obj);

        dispatch(dispatcherActions.ShowStopDateTimePicker, false);
    }

    function onSearchLocationsClick() {
        if (!isEditable) return;
        handleCommands(PilotAssignmentCommands.SearchLocations, location);
    }

    function onConfirmDelete() {
        if (!isEditable) return;
        setShowDialog(false);
        handleCommands(PilotAssignmentCommands.DeleteLocation, location);
    }
})

const LocationPilotTypeCard = (
    {
        handleCommands,
        location,
        isEditable,
        hasValidationError
    }) => {

    const [selectedPilotType, setSelectedPilotType] = useState(null);
    const [pilotTypes, setPilotTypes] = useState([]);

    useEffect(() => {

        async function initializeAsync() {
            

            let pilotType = {};
            if (location.pilotTypeId > 0) {
                pilotType = await PilotagePilotTypeIdbRepository.getAsync(location.pilotTypeId);
                setSelectedPilotType(pilotType);
            }

            const pilotTypes = []
            const dtos = await PilotagePilotTypeIdbRepository.getAllAsync();

            for (const dto of dtos) {
                pilotTypes.push({
                    name: dto.name,
                    selected: dto.systemName === pilotType.systemName,
                    source: dto
                })
            }

            setPilotTypes(pilotTypes);
        }

        initializeAsync();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    return (
        <SelectOptionFieldWrapper
            hasValidationError={hasValidationError}
            title="Losingstype"
            options={pilotTypes}
            selectedOption={selectedPilotType}
            optionTextField="name"
            onCallback={onCallback}
            disabled={!isEditable}
        />
    )

    function onCallback(item) {
        handleCommands(PilotAssignmentCommands.UpdateLocationPilotType, {
            location: location,
            pilotType: item.source
        });
    }
}

const LocationStartStopCard = (
    {
        title,
        subTitle,
        time,
        show,
        onShow,
        onClose,
        onChange,
        hasValidationError,
        isEditable
    }) => {

    return (
        <FieldWrapper>
            <Label hasValidationError={hasValidationError} >
                {title}:
            </Label>
            <div className="k-form-field-wrap">
                <DateTimePicker
                    disabled={!isEditable}
                    hasValidationError={hasValidationError}
                    onClick={onShow}
                    title={subTitle}
                    value={time}
                    show={show}
                    onClose={onClose}
                    onChange={onChange}
                    mode={DateTimePickerModes.DateTime}
                />
            </div>
        </FieldWrapper>
    )
}

const LocationBoardingTypeCard = (
    {
        location,
        handleCommands,
        isFirst,
        isLast,
        isEditable,
        hasValidationError
    }) => {

    const [boardingType, setBoardingType] = useState({});
    const [boardingTypes, setBoardingTypes] = useState([]);

    useEffect(() => {

        async function initializeAsync() {

            let boardingType = await PilotBoardingTypeIdbRepository.getDefaultAsync();

            const boardingTypes = []
            const isPilotBoardingTypeOffBoarding = location.isPilotBoardingTypeOffBoarding;
            const boardingOnly = isFirst;
            const offBoardingOnly = isLast;

            if (!isObjectNull(location.pilotBoardingType)) {
                boardingType = await PilotBoardingTypeIdbRepository.getBySystemNameAsync(location.pilotBoardingType.systemName);
            }

            if (boardingOnly || (!boardingOnly && !offBoardingOnly)) {
                boardingTypes.push({
                    name: parse("Bording Losb&aring;t"),
                    selected: boardingType.systemName === "PILOT_BOAT" && !isPilotBoardingTypeOffBoarding,
                    guid: uuid(),
                    source: {
                        isPilotBoardingTypeOffBoarding: false,
                        systemName: "PILOT_BOAT"
                    }
                });
                boardingTypes.push({
                    name: "Bording Helikopter",
                    selected: boardingType.systemName === "HELICOPTER" && !isPilotBoardingTypeOffBoarding,
                    guid: uuid(),
                    source: {
                        isPilotBoardingTypeOffBoarding: false,
                        systemName: "HELICOPTER"
                    }
                });
            }
            if (offBoardingOnly || (!boardingOnly && !offBoardingOnly)) {
                boardingTypes.push({
                    name: parse("Kvitting Losb&aring;t"),
                    selected: boardingType.systemName === "PILOT_BOAT" && (isPilotBoardingTypeOffBoarding || isLast),
                    guid: uuid(),
                    source: {
                        isPilotBoardingTypeOffBoarding: true,
                        systemName: "PILOT_BOAT"
                    }
                });
                boardingTypes.push({
                    name: "Kvitting Helikopter",
                    selected: boardingType.systemName === "HELICOPTER" && (isPilotBoardingTypeOffBoarding || isLast),
                    guid: uuid(),
                    source: {
                        isPilotBoardingTypeOffBoarding: true,
                        systemName: "HELICOPTER"
                    }
                });
            }
            boardingTypes.push({
                name: "Ikke relevant",
                selected: boardingType.systemName === "NOT_APPLICABLE",
                guid: uuid(),
                source: {
                    isPilotBoardingTypeOffBoarding: false,
                    systemName: "NOT_APPLICABLE"
                }
            });

            setBoardingTypes(boardingTypes);

            const selectedBoardingType = boardingTypes.find(bt => bt.selected === true);
            if (!isObjectNull(selectedBoardingType)) {
                setBoardingType(selectedBoardingType);
            }
        }

        initializeAsync();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    return (
        <SelectOptionFieldWrapper
            hasValidationError={hasValidationError}
            title={getTitle()}
            options={boardingTypes}
            selectedOption={boardingType}
            optionTextField="name"
            onCallback={onCallback}
            disabled={!isEditable}
        />
    )

    function onCallback(item) {
        handleCommands(PilotAssignmentCommands.UpdateLocationBoardingType, {
            location: location,
            boardingType: item.source
        });
    }

    function getTitle() {
        if (!isFirst && !isLast) return "Bording/Kvitting";

        if (isFirst) {
            return "Bording";
        }
        return "Kvitting";
    }
}

const LocationPilotTypeAddLocationCard = ({
    handleCommands,
    location,
    locations,
    pilotAssignment,
    isEditable,
    hasPilotTypeValidationError,
    onSearchLocationsClick,
    isDirty
}) => {

    const [iconOnly, setIconOnly] = useState(window.innerWidth <= 500 ? true : false);

    useEffect(() => {

        function handleResize() {
            setIconOnly(window.innerWidth <= 500 ? true : false);
        }

        window.addEventListener("resize", handleResize);

        return (() => {
            window.removeEventListener("offline", handleResize);
        });

    }, [])

    return (
        <>
        {
        isEditable &&
            <div className="pp-type-location">
                <div>
                    <Card
                        properties={{
                            ...CardProperties,
                            borderColor: "var(--card-border)",
                            backgroundColor: "var(--card-body-bg)",
                            hasValidationError: hasPilotTypeValidationError,
                            isDirty: isDirty
                        }}>
                        <div>
                            <LocationPilotTypeCard
                                handleCommands={handleCommands}
                                location={location}
                                locations={locations}
                                pilotAssignment={pilotAssignment}
                                isEditable={isEditable}
                                hasValidationError={hasPilotTypeValidationError}
                            />
                        </div>
                    </Card>

                </div>

                <div onClick={onSearchLocationsClick}>
                    <div>Legg til lokasjon</div>
                    <div>
                        <MapPinPlus size={iconOnly ? 50 : 40} />
                    </div>
                </div>
            </div>
            }
        </>
       
    )
}
